import * as actionTypes from '../actions/actionTypes';


const initialState = {
   instantCampaign:{
    isLoading: false,
    isError: false,
    data:{},
   },
   campaigns:{
    campaigns:[],
    count: "",
    isLoading: false,
    isError: false
   }
  }

  const instantCampaignReducer = (state = initialState, action = {}) => {


    const newState = { ...state };
  
    switch (action.type) {
      case actionTypes.CREATE_INSTANT_CAMPAIGN:
        return { ...newState, instantCampaign: { ...newState.instantCampaign, isLoading: true } };

        case actionTypes.CREATE_INSTANT_CAMPAIGN_SUCCESS:
          return { ...newState, instantCampaign: {data:{...action.payload},isLoading: false, isError: false} };

      case actionTypes.CREATE_INSTANT_CAMPAIGN_FAILURE:
        return { ...newState, instantCampaign: {data:{...action.payload},isLoading: false, isError: true} };

        case actionTypes.FETCH_INSTANT_CAMPAIGNS_LIST:
          return { ...newState, campaigns: { ...newState.campaigns, isLoading: true }  };
    
        case actionTypes.FETCH_INSTANT_CAMPAIGNS_LIST_SUCCESS:
          return { ...newState, campaigns: {campaigns:[...action.payload.instantMsgList],count:action.payload.totalCount,isLoading: false, isError: false}  };
    
        case actionTypes.FETCH_INSTANT_CAMPAIGNS_LIST_FAILURE:
          return {...newState, campaigns: { count: action.payload.totalCount, isLoading: false, isError: true } };

      default:
        return newState;
    }
  };
  
  
  export default instantCampaignReducer;