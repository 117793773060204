import React, { useEffect, useRef, useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Grid, TextField, Typography } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ButtonComponent from '../components/ButtonGroup';


import * as Styled from '../components/styled-components/Campaign';
import * as settingsActions from '../store/actions/settings';
import * as menuActions from '../store/actions/breadcrumb';

import SpinningLoader from '../components/SpinningLoader';
import appConfig from '../config/appConfig';



const Settings = ({ settingsData, fetchEmailConfiguration, createEmailConfiguration, updateEmailConfiguration, setBreadcrumb }) => {

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [editorState2, setEditorState2] = useState(EditorState.createEmpty());

    const [content, setContent] = useState();
    const [content2, setContent2] = useState();
    const [subject, setSubject] = useState();

    const editorRef = useRef();
    const editorRef2 = useRef();

    const [selectedLang, setSelectedLang] = useState("EN");


    useEffect(() => {
        fetchEmailConfiguration(selectedLang.toLowerCase());
        setBreadcrumb('Email Configuration')
    }, [selectedLang]);

    useEffect(() => {
        const headerContentBlock = settingsData?.emailSettings?.data?.header ? htmlToDraft(settingsData?.emailSettings?.data?.header.replace(/<[^/>][^>]*><\/[^>]+>/gim, "")) : null;
        const footerContentBlock = settingsData?.emailSettings?.data?.footer ? htmlToDraft(settingsData?.emailSettings?.data?.footer.replace(/<[^/>][^>]*><\/[^>]+>/gim, "")) : null;
        setSubject(settingsData?.emailSettings?.data?.subject)

        if (headerContentBlock) {
            const headerContent = ContentState.createFromBlockArray(headerContentBlock.contentBlocks);
            const headerEditor = EditorState.createWithContent(headerContent);
            setEditorState(headerEditor);
            setContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        }
        if (footerContentBlock) {
            const footerContent = ContentState.createFromBlockArray(footerContentBlock.contentBlocks);
            const footerEditor = EditorState.createWithContent(footerContent);
            setEditorState2(footerEditor);
            setContent2(draftToHtml(convertToRaw(editorState2.getCurrentContent())));
        }

        if (editorRef && editorRef.current !== null) {
            setTimeout(()=>{
                editorRef?.current?.focusEditor();
                editorRef2?.current?.focusEditor();
            },1000)
        }

    }, [JSON.stringify(settingsData.emailSettings), selectedLang])


    const handleEditorChange = (state) => {
        setEditorState(state);
        setContent(`<div>${draftToHtml(convertToRaw(editorState.getCurrentContent()))}</div>`);
    }

    const handleEditorChange2 = (state) => {
        setEditorState2(state);
        setContent2(`<div>${draftToHtml(convertToRaw(editorState2.getCurrentContent()))}</div>`);
    }

    const filterHandleChange = (e) => {
        if (e.target.textContent) {
            setSelectedLang(e.target.textContent);
        }
    }

    const handleSubjectChange = (e) =>{
        setSubject(e.target.value)
    }

    const saveConfiguration = () => {
        createEmailConfiguration(content, content2, subject, selectedLang.toLowerCase());
    }

    const updateConfiguration = () => {
        updateEmailConfiguration(content, content2, subject,selectedLang.toLowerCase(), settingsData?.emailSettings?.data?.id)
    }

    const uploadFileCallback = (file) => {
        return new Promise(
            (resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.addEventListener('load', () => {
                    resolve({ data: { link: reader.result } });
                });
            }
        );
    }


    const filters = ['EN', 'ES', 'PT', 'VI', 'HT', 'ZH'];

    return <div>

        <Grid container>
            <Grid item sm={12} xs={12} lg={6} md={6} xl={6}>
            <h1 className='page-heading' ><b>Email Configuration</b></h1>
            </Grid>
            <Grid item sm={12} xs={12} lg={6} md={6} xl={6}>
                <div className='flt-r'><ButtonComponent filters={filters} activeFilter={selectedLang} onClick={filterHandleChange} /></div>
            </Grid>
        </Grid>
        <hr className='blue-border' />
            <Grid container sx={{mt:4}}>
                <Grid item md={10} xl={10} lg={10} sm={12} sx={{ mb: 1 }}>
                    <Typography component="h2">Subject Configuration</Typography>
                    <TextField
                        margin="normal"
                        id="subject"
                        fullWidth
                        onChange={(e)=>handleSubjectChange(e)}
                        value={subject}
                    />
                </Grid>

                <Grid item md={10} xl={10} lg={10} sm={12} sx={{ mb: 2 }}>
                    <Typography sx={{ mb: 2 }} component="h2">Header Configuration</Typography>
                    {!settingsData?.emailSettings?.isLoading ?
                        <Editor
                            tabIndex={0}
                            handlePastedText={() => false}
                            ref={editorRef}
                            editorState={editorState}
                            toolbar={
                                {
                                    image:{urlEnabled: true,uploadEnabled: true, previewImage: true,inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg', uploadCallback: uploadFileCallback},
                                    fontFamily: {
                                        options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana','Source Sans Pro'],
                                      },
                            }}
                            editorStyle={{ border: "1px solid #ccc", height: "200px", padding: "15px" }}
                            toolbarStyle={{ border: "1px solid #ccc", paddingRight: "10%" }}
                            onEditorStateChange={(state) => { handleEditorChange(state) }}
                        /> : <SpinningLoader circleColor={appConfig.colors.darkblue} />}
                </Grid>

                <Grid item md={10} xl={10} lg={10} sm={12} sx={{ mb: 2 }}>
                    <Typography sx={{ mb: 2 }} component="h2">Footer Configuration</Typography>
                    {!settingsData?.emailSettings?.isLoading ?
                        <Editor
                        tabIndex={0}
                            ref={editorRef2}
                            editorState={editorState2}
                            handlePastedText={() => false}
                            editorStyle={{ border: "1px solid #ccc", height: "300px", padding: "15px" }}
                            toolbarStyle={{ border: "1px solid #ccc", paddingRight: "10%" }}
                            toolbar={
                                {
                                    image:{urlEnabled: true,uploadEnabled: true, previewImage: true,inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg', uploadCallback: uploadFileCallback},
                                    fontFamily: {
                                        options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana','Source Sans Pro'],
                                      },
                            }}
                            onEditorStateChange={(state) => { handleEditorChange2(state) }}
                        /> : <SpinningLoader circleColor={appConfig.colors.darkblue} />}
                </Grid>
                <Grid item md={10} xl={10} lg={10} sm={12} sx={{ mb: 2 }} className='text-align-right'>
                {settingsData?.emailSettings?.data?.header && settingsData?.emailSettings?.data?.footer === "" ?
                    <Styled.CampaignButton onClick={() => saveConfiguration()} type="submit" variant="contained" >
                        Save Configuration
                    </Styled.CampaignButton>
                    :
                    <Styled.CampaignButton onClick={() => updateConfiguration()} type="submit" variant="contained" >
                        Update Configuration
                    </Styled.CampaignButton>}
                </Grid>

            </Grid>
    </div>
}

Settings.propTypes = {
    settingsData: PropTypes.objectOf(PropTypes.object).isRequired,
    fetchEmailConfiguration: PropTypes.func.isRequired,
    createEmailConfiguration: PropTypes.func.isRequired,
    updateEmailConfiguration: PropTypes.func.isRequired,
    setBreadcrumb: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({ settingsData: state.settings })

const mapDispatchToProps = (dispatch) => {
    return {
        fetchEmailConfiguration: (lang) => dispatch(settingsActions.fetchEmailConfiguration(lang)),
        createEmailConfiguration: (headerData, footerData, selectedLang) => dispatch(settingsActions.createEmailConfiguration(headerData, footerData, selectedLang)),
        updateEmailConfiguration: (headerData, footerData,subject, selectedLang, id) => dispatch(settingsActions.updateEmailConfiguration(headerData, footerData,subject, selectedLang, id)),
        setBreadcrumb: (menu) => dispatch(menuActions.changeMenu(menu))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);