const appConfig = {
  fontfamily: '"Source Sans Pro", sans-serif !important',
  font16: '16px',
  font18: '18px',
  colors: {
    white: '#FFFFFF !important',
    aliceblue: '#EBF4FA',
    mildyellow: '#fef9e8',
    darkblue: '#365162 !important',
    black: '#000000',
    tangaroa: '#001e3e',
    blueprimary: '#005974',
    btnBlue: '#005974',
    verylightshadegray: '#fafafa',
    lightgrey: '#f2f2f2',
    grey: 'rgba(117,117,117,0.2)',
    darkgray: '#535353',
    green: '#388557',
    lightblue: '#dff2ff',
    chinesesilver: '#ccc',
    blue: '#1976d2',
    yellow: '#f1c11a',
    sonicSliver: '#757575',
    prussianBlue: '#002A57',
    pastelBlue: '#b3bfcd',
    lightBlue: '#669bac',
    red: '#FF0000',
    davyGrey: '#5A5A5A',
    grayColor: 'gray'
  },
  bordergray: { border: '1px solid #ccc' },
  p0: { padding: '0' },
  p00: { padding: '0 .75rem' },
  p07: { padding: '.75rem' },
  margin: '0 auto',
  m0: { margin: 0 },
  mt03: { marginTop: '0.3rem' },
  mt15: { marginTop: '1.5rem' },
  mr05: { marginRight: '0.5rem' },
  w65: { width: '65%' },
  w95: { width: '95%' },
  w100: '100%',
  w20: '20rem',
  w100p: { width: '100%' },
  width78: { width: '78rem' },
  width59: { width: '59.7rem' },
  width51: { width: '51.7rem' },
  width64: { width: '64rem' },
  maxW200: { maxWidth: '200px' },
  minW24: { minWidth: '24px' },
  minW35: { minWidth: '35px' },
  height37: '37px',
  height29: '29px',
  height44: { height: '44px' },
  height3: { height: '3.4375rem' },
  z: { zIndex: '9999' },
  cursorpointer: { cursor: 'pointer' },
  overflowY: { overflowY: 'auto' },
  liststyle: { listStyle: 'none' },
  fontSize16: { fontSize: '16px' },
  checkboxStyleChecked: {
    marginLeft: "2px",
    marginBottom: '10px',
    background: '#365162 !important',
    color: '#FFFFFF !important',
    borderRadius: "50px",
    padding: "0 10px",
    width: "21rem"
  },
  checkboxStyle: {
    marginLeft: "2px",
    marginBottom: '10px',
    border: '1px solid #365162 !important',
    color: '#365162 !important',
    borderRadius: "50px",
    padding: "0 15px", 
    width: "21rem"
  }
}

export default appConfig
