import axios from 'axios';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig,loginRequest,origin } from "../../config/config";

const systemInfo = JSON.parse(window.sessionStorage.getItem("systemInfo"));

function getRefreshToken() {
    return new Promise( (resolve) => {
      if (sessionStorage.getItem('oauthToken') != null) {
        try {
          const msalInstance = new PublicClientApplication(msalConfig);
          const currentAccount = JSON.parse(window.sessionStorage.getItem('home_account'));
          const silentRequest = {
              scopes: loginRequest.scopes,
              account: currentAccount,
          };
          msalInstance.ssoSilent(silentRequest).then((response)=>{
            const oauth = {
              token_type: "Bearer",
              access_token: response.idToken
            }
            window.sessionStorage.setItem("oauthToken", JSON.stringify(oauth));
            resolve(oauth)
          }).catch(err=>{
            console.log(err);
            msalInstance.logoutRedirect();
          })
          
        } catch (err) {
            console.log("Refresh token error",err)
        }
      }
    });
  }

const request = axios.create({
    headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': origin,
    }
});


request.interceptors.request.use((config) => {
  const authDetails = JSON.parse(window.sessionStorage.getItem("oauthToken"));
  const userDetails = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const configObj = config;

    const audit = {
        emailId: userDetails?.emailId || "",
        firstName: userDetails?.firstName || "",
        lastName: userDetails?.lastName || "",
        userId: userDetails?.userId || "",
        accountId: userDetails?.accountId || "",
        ip: systemInfo?.ip || "",
        deviceId: systemInfo?.deviceId || "",
    }
    const headers = {
        ...config.headers,
        Audit: config.url.includes("audit") || config.url.includes("lov") ? "" : JSON.stringify(audit),
        "X-ROLE-ID": userDetails?.role || ""
    }   

    if (authDetails && authDetails?.access_token) {
        configObj.headers = {
            ...headers,
            Authorization: `Bearer ${authDetails?.access_token}`
        };
    } else {
        configObj.headers = {
            ...headers,
        };
    }
    return configObj;
},
    (error) => {
        // Do something with request error
        console.log("request error", error)
        return Promise.reject(error)
    }
);

request.interceptors.response.use((response) => {
    // Do something with response data
    return response.data
}, (error) => {
    // Do something with response error
    const originalRequest = error.config;
    if ((error.response.status === 401)) {
        originalRequest.retry = true;
         return getRefreshToken().then(() => {
          const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
          const configuration = error.config;
          configuration.headers.Authorization = `${oauthToken.token_type} ${oauthToken.access_token}` ;
          return new Promise((resolve, reject) => {
            axios.request(configuration).then(response => {
                resolve(response.data);
              })
              .catch(resErr => {
                reject(resErr);
              });
          });
        });
    }
      return Promise.reject(error)
}
);




export default request;