/* eslint-disable */
import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { Box, CssBaseline, Toolbar, IconButton, List, ListItem, Drawer, ListItemText } from '@mui/material';
import AppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Swal from "sweetalert2";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useMsal } from "@azure/msal-react";

import { Link } from 'react-router-dom';
import SideMenuItems from "./SideMenuItems";
// import BreadCrumbBar from '../components/BreadCrumbBar';
import { azureLogOut } from "../store/actions/auth";
import appConfig from "../config/appConfig";
import brandLogo from '../assets/K-Notify.png';

const CustomAppBar = styled(AppBar)`
  background-color: #fff!important; 
  color: #FFFBF9;
  box-shadow: 0px 3px 6px #00000029;

`;


const HeaderWrapper = styled.div`
    margin-left: 230px;
    display: flex;
    width: 400px;
    @media (max-width: 1024px) {
      display:none
  }

  @media (min-width:1000px ) and (max-width: 1200px) {
    margin-left: 0;
  }
`;

const Header = ({ authDetails, breadcrumb, showLogo="false" }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const activeMenu = window.location.pathname.slice(1).split('/')[0];
  const isRightMenuOpen = Boolean(anchorEl);
  const { instance } = useMsal();

  const userDetails = JSON.parse(window.sessionStorage.getItem("userInfo"));
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const signOutHandler = () => {
    azureLogOut(instance)
  }

  useEffect(() => {
    if (!authDetails?.isError && authDetails?.signOutDetails?.status === 1) {
      Swal.fire({
        icon: "success",
        button: false,
        showConfirmButton: false,
        timer: 2000,
        text: "Logged out successfully",
      });
      setTimeout(() => {
        window.location.href = '/signout';
        window.sessionStorage.removeItem("userDetails");
        window.sessionStorage.removeItem("authDetails");
        window.sessionStorage.removeItem("campaign");
      }, 2000);
    } else if (authDetails?.errorMessage?.status === 0) {
      window.location.href = '/login';
      window.sessionStorage.removeItem("userDetails");
    }
  }, [JSON.stringify(authDetails)])

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
       <CustomAppBar className="appBar" position="fixed">
          <Toolbar>
          
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ marginRight: 'auto', color: '#686F6F', mr: 2, display: { xs: 'block', sm: 'block', xl: 'none', lg: 'none', md: 'block' } }}
            >
              <MenuIcon />
            </IconButton>
         
            {/* {showLogo  && (
              <div style={{position: 'relative', top: '35px'}}><img height={80} src={brandLogo} alt="logo" /></div>
            )} */}
            <HeaderWrapper>
              {/* <BreadCrumbBar label={breadcrumb.currentMenu} /> */}
            </HeaderWrapper>
            <IconButton sx={{
              marginLeft: 'auto',
              color: '#686F6F'
            }}
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
            color="inherit"
          />
            &nbsp;
            &nbsp;
            &nbsp;
            
            {/* <p style={{ color: "#000", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>Hello, {userDetails && Object.keys(userDetails) ? `${userDetails.firstName.charAt(0).toUpperCase() + userDetails.firstName.slice(1)} ${userDetails.lastName.charAt(0).toUpperCase() + userDetails.lastName.slice(1)}` : "User"}</p> * {userDetails && Object.keys(userDetails) ? `${userDetails.firstName} ${userDetails.lastName}` */}
            <p style={{ color: "#000", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>Hello, Ashley Carleton</p> {/** {userDetails && Object.keys(userDetails) ? `${userDetails.firstName} ${userDetails.lastName}` */}

            &nbsp;
            <IconButton sx={{
              color: '#686F6F',
              marginRight: "10px"
            }}
              onClick={handleProfileMenuOpen}
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit"
            >
              <AccountCircle />
            </IconButton>

          </Toolbar>
        </CustomAppBar>


        <Drawer
          open={open}
          onClose={handleDrawerClose}
        >
            <>
        <div style={{float:"left", padding:"15px 20px"}}>
        <img height={60} alt="mass-logo" src={brandLogo}/>
              </div>
              <List>
                {SideMenuItems.map((menu) => {
                  return (userDetails?.role === "NMSUSR" ? menu.isUserRoute : userDetails?.role === "NMSBSR" ? menu.isBusRoute : menu.isAdminRoute) &&
                    <ListItem key={menu.route}
                      className="sideMenuItem"
                      sx={{
                        height: "35px",
                        border: '1px solid #ddd',
                        "&.Mui-selected": {
                          color: "#365162",
                          backgroundColor: "#EDF6F4",
                          height: "35px",
                        }
                      }}

                      selected={activeMenu === menu.route || menu.subRoutes.includes(activeMenu)}>
                      <Link key={menu.route} to={`/${menu.route}`} className='sideMenuLink'>
                        <ListItemText key={menu.route} primary={menu.name} sx={{ '& .MuiTypography-root': { fontFamily: appConfig.fontfamily } }} />
                      </Link>
            </ListItem>


                })}
              </List>
            </>
          </Drawer>
      <Menu
        style={{ marginTop: "40px" }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isRightMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem sx={{ color: appConfig.colors.darkblue, textDecoration: "underline" }} onClick={signOutHandler}  >Logout</MenuItem>
      </Menu>
    </Box>
  );
}



const mapStateToProps = (state) => ({ authDetails: state.auth, breadcrumb: state.breadcrumbs })


export default connect(mapStateToProps, null)(Header);

Header.propTypes = {
  authDetails: PropTypes.object.isRequired,
  breadcrumb: PropTypes.object.isRequired
}