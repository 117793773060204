import React from "react";
import {Routes,Route,Navigate } from "react-router-dom";
import MainLayout from '../Layout/MainLayout';
// import TenantLayout from '../Layout/TenantLayout';
import SignIn from "../pages/SignIn";
import Campaign from "../pages/Campaign";
import CampaignSnapshot from "../pages/CampaignSnapshot";
// import OptOut from "../pages/Optout";
import CreateCampaign from "../pages/CreateCampaign";
import Users from "../pages/Users";
import Template from "../pages/Template";
import CreateTemplate from "../pages/CreateTemplate";
import EditTemplate from "../pages/EditTemplate";
import  TwoWayChat from '../pages/TwoWayChat';
import EmailConfiguration from "../pages/EmailConfiguration";
import Reports from "../pages/Reports";
import OptoutList from "../pages/OptoutList";
import Audits from "../pages/Audits";
import Reconcile from "../pages/Reconcile";
import ErrorLogs from "../pages/ErrorLogs";
import Dashboard from "../pages/Dashboard";
// import DashboardV3 from "../pages/Dashboardv3";
import CreateUser from "../pages/CreateUser";
import EditUser from "../pages/EditUser";
import Broadcast from "../pages/instant-notifications/Broadcast";
import InstantMessages from "../pages/instant-notifications/InstantMessages";
import UploadFile from "../pages/UploadFile";
import CampaignFiles from "../pages/CampaignFiles";
import Gloassary from "../pages/Gloassary";
// import TenantSelection from '../pages/TenantSelection';

const NotFound =  () => {
    return (
            <div style={{textAlign:"center"}}>
                <h2>Oh ho! 404, Page Not Found</h2>
                <span>Click to return <a href="/">Home Page</a></span>
            </div>
    );
};

const wrapWithLayout = (comp) =>{
  return <MainLayout>{comp}</MainLayout>
}
// const wrapWithTenantLayout = (comp) =>{
//   return <TenantLayout>{comp}</TenantLayout>
// }

const AppRoutes =  () => {
    const auth = JSON.parse(window.sessionStorage.getItem('userInfo'))
    if(!auth) {
        return (
        <Routes>
        <Route path="/login" element={<SignIn />} />
        <Route path="/" element={<SignIn />} />
        {/* <Route exact path="/optout" element={<OptOut/>} /> */}
        <Route path="*" element={<Navigate to="/login" />} />
     </Routes>
     )
    }

    return (
      <Routes>
        <Route path="/campaign" element={wrapWithLayout(<Campaign/>)} />
        {/* <Route exact path="/dashboard" element={wrapWithLayout(<DashboardV3/>)} /> */}
        <Route exact path="/dashboard" element={wrapWithLayout(<Dashboard/>)} />
        <Route path="/templates" element={wrapWithLayout(<Template/>)} />
        <Route path="/two-way-chat" element={wrapWithLayout(<TwoWayChat />)}/>
        <Route exact path="/" element={wrapWithLayout(<Dashboard/>)} />
        <Route exact path="/campaigndetails/:id" element={wrapWithLayout(<CampaignSnapshot/>)} />
        <Route exact path="usermanagement" element={wrapWithLayout(<Users/>)} /> 
        <Route exact path="/createcampaign" element={wrapWithLayout(<CreateCampaign/>)}/>
        <Route exact path="/createtemplate" element={wrapWithLayout(<CreateTemplate/>)}/>
        <Route exact path="/edittemplate" element={wrapWithLayout(<EditTemplate/>)}/>
        <Route exact path="/createuser" element={wrapWithLayout(<CreateUser/>)}/>
        <Route exact path="/edituser" element={wrapWithLayout(<EditUser/>)}/>

        <Route exact path="/staffalerts" element={wrapWithLayout(<Broadcast/>)}/>
        <Route exact path="/instant" element={wrapWithLayout(<InstantMessages/>)}/>
        <Route path="/emailconfiguration" element={wrapWithLayout(<EmailConfiguration />)}/>
        <Route path="/reports" element={wrapWithLayout(<Reports />)}/>
        <Route path="/errorlog" element={wrapWithLayout(<ErrorLogs />)}/>
        <Route path="/optoutlist" element={wrapWithLayout(<OptoutList />)}/>
        <Route path="/audits" element={wrapWithLayout(<Audits />)}/>
        <Route path="/reconcile" element={wrapWithLayout(<Reconcile />)}/>
        <Route path="/uploadfile" element={wrapWithLayout(<UploadFile />)}/>
        <Route path="/campaignfiles" element={wrapWithLayout(<CampaignFiles />)}/>
        <Route path="/glossary" element={wrapWithLayout(<Gloassary />)}/>
        {/* <Route path="/tenantSelection" element={wrapWithTenantLayout(<TenantSelection />)}/> */}

        <Route path="/signout" element={<Navigate to="/login" />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
    )
};


export default AppRoutes;
















