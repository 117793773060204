import * as dashboardConstants from './actionTypes';
import {config} from '../../config/config';
import request from '../../components/Helper/Request';

const userDetails = JSON.parse(window.sessionStorage.getItem("userInfo"));

export const getDashboardDetails = (filter) => {
  return (dispatch) => {
    dispatch({
      type: dashboardConstants.FETCH_DASHBOARD_DETAILS
    });
      request({
          method: 'get',
          url:`${config.API_URL}/api/dashboard/view2/${userDetails?.accountId}/${userDetails?.userId}`,   
          params:{
            startDate: filter.startDate,
            endDate: filter.endDate,
            filterByChannel: filter.filterBy,
            filterByTemplateCode: filter.templateCode,
            filterByCampaign: filter.filterByCampaign,
            filterByLanguage:""
          }
      })
      .then((response) => {
        dispatch({
          type: dashboardConstants.FETCH_DASHBOARD_DETAILS_SUCCESS,
          payload: response
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardConstants.FETCH_DASHBOARD_DETAILS_FAILURE,
          payload:error
        });
      })
  }

}
// export const getDashboardDetails = (filter) => {
//   return (dispatch) => {
//     dispatch({
//       type: dashboardConstants.FETCH_DASHBOARD_DETAILS
//     });
//       request({
//           method: 'get',
//           url:`${config.API_URL}/api/dashboard/${userDetails?.accountId}/${userDetails?.userId}`,   
//           params:{
//             startDate: filter.startDate,
//             endDate: filter.endDate,
//             filterByChannel: filter.filterBy,
//             filterByTemplateCode: filter.templateCode,
//             filterByLanguage:""
//           }
//       })
//       .then((response) => {
//         dispatch({
//           type: dashboardConstants.FETCH_DASHBOARD_DETAILS_SUCCESS,
//           payload: response
//         });
//       })
//       .catch((error) => {
//         dispatch({
//           type: dashboardConstants.FETCH_DASHBOARD_DETAILS_FAILURE,
//           payload:error
//         });
//       })
//   }

// }



  export const getDashboardDetailsV3 = (filter) => {
    return (dispatch) => {
      dispatch({
        type: dashboardConstants.FETCH_DASHBOARD_DETAILS
      });
        request({
            method: 'get',
            url:`${config.API_URL}/api/dashboard/${userDetails?.accountId}/${userDetails?.userId}`,   
            params:{
              startDate: filter.startDate,
              endDate: filter.endDate,
              viewAs: filter.viewAs,
              timezone: filter.timeZone
            }
        })
        .then((response) => {
          dispatch({
            type: dashboardConstants.FETCH_DASHBOARD_DETAILS_SUCCESS,
            payload: response
          });
        })
        .catch((error) => {
          dispatch({
            type: dashboardConstants.FETCH_DASHBOARD_DETAILS_FAILURE,
            payload:error
          });
        })
    }
  
  }


