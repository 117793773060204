/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Collapse, Grid, Paper, Link as MaterialLink } from '@mui/material';
import { useMsal } from "@azure/msal-react";
import { connect } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import Swal from "sweetalert2";
import PropTypes from 'prop-types';
import brandLogo from '../assets/K-Notify.png';
import UpArrow from '../assets/up-arrow_header.svg';
import LockLarge from '../assets/Lock-large.svg'
import LockSmall from '../assets/lock-small-icon.svg'
import GovtIcon from '../assets/govt-small-icon.svg'
import * as Styled from '../components/styled-components/Campaign';
import SpinningLoader from '../components/SpinningLoader';


// Azure

import * as signInActions from '../store/actions/auth';


// Azure Config

import { loginRequest } from "../config/config";
import { setCookie, getCookie } from '../components/Helper/cookies';
import appConfig from '../config/appConfig';





const SignIn = ({ authDetails, azureLogOut, getClientInfo }) => {
  const { instance } = useMsal();
  const [userStatus, setUserStatus] = useState({ isLoginError: false, isUserResetPassword: false });
  const [checked, setChecked] = useState(false)

  const clearCookies = () => {
    setCookie("isLoginFailed", "");
    setCookie("isUserResetPassword", "");
    setUserStatus({ isLoginError: false, isUserSignUp: false, isUserResetPassword: false })
  }

  const routeUser = (userDetails) => {
    if (userDetails) {
      window.location.href='/dashboard';
     }
  }

  useEffect(() => {
    if (getCookie("isLoginFailed") === "true") {
      setUserStatus({ ...userStatus, isLoginError: true })
      const swalWithCustompButtons = Swal.mixin({
        customClass: {
            confirmButton: 'custom-ok-button',
        },
        buttonsStyling: false
    })
    swalWithCustompButtons.fire({
        title: "",
        text: 'Authentication (login or password) has failed. Please try again',
        showCancelButton: false,
        confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
      clearCookies();
    } 
    })
    }
    if (getCookie("isUserResetPassword") === "true") {
      setUserStatus({ ...userStatus, isUserResetPassword: true })
    }

    if (window.sessionStorage.getItem("isLogout") === "true"){
      sessionStorage.clear();
      localStorage.clear();
    }

    instance.addEventCallback(async (event) => {
      if (event.eventType === "msal:loginSuccess") {
        if (event?.payload?.idTokenClaims?.isForgotPassword) {
          setCookie("isUserResetPassword", true);
          azureLogOut(instance);
        }
        const myAccount = instance.getAccountByHomeId(event.payload.account.homeAccountId);
        instance.setActiveAccount(myAccount);
        window.sessionStorage.setItem("home_account", JSON.stringify(myAccount));
        window.sessionStorage.setItem("user_name", event.payload.account.idTokenClaims.preferred_username);
        window.sessionStorage.setItem("id_token", event.payload.idToken);
        const oauth = {
          token_type: "Bearer",
          access_token: event.payload.idToken
        }
        window.sessionStorage.setItem("oauthToken", JSON.stringify(oauth));
         getClientInfo(instance);
      }
      if (event.eventType === "msal:loginFailure" || event.eventType === "msal:acquireTokenFailure") {
        azureLogOut(instance);
      }

    }, error => {
      console.log('error', error);
    });

  }, [])


  useEffect(() => {
    const { loginDetails } = authDetails;
    if (authDetails.isLoginSuccessfull) {
      routeUser(loginDetails);
    }
  }, [JSON.stringify(authDetails.loginDetails)])



  const handleAzureLogin = (msalIns) => {
    clearCookies();
    msalIns.loginRedirect(loginRequest).then().catch(e => {
      console.error(e);
    });
  }

  const bypassLogin = (msalIns) => {
    // const ha = { "homeAccountId": "659ea999-fd94-42b1-ad41-bfb67fdd257b.3e861d16-48b7-4a0e-9806-8c04d81b7b2a", "environment": "login.windows.net", "tenantId": "3e861d16-48b7-4a0e-9806-8c04d81b7b2a", "username": "Aravinth.Sridharan@mass.gov", "localAccountId": "659ea999-fd94-42b1-ad41-bfb67fdd257b", "name": "Sridharan, Aravinth (DTA)", "idTokenClaims": { "aud": "44327953-b778-4885-b8bc-e94aa9014baa", "iss": "https://login.microsoftonline.com/3e861d16-48b7-4a0e-9806-8c04d81b7b2a/v2.0", "iat": 1692168378, "nbf": 1692168378, "exp": 1692172278, "aio": "AWQAm/8UAAAASNQwJAYGILK+xCo9VVa0f/GzhS5HcyHRH0dT8T0/1gJHTIb8eVRo1igqz/XTC7h6/64CZ9Og8rwyz780EaxQh9tr0X5n9NUSxYAyfzQpLk++1B6KZvPUMEBcBwU/QZL5", "email": "Aravinth.Sridharan@mass.gov", "name": "Sridharan, Aravinth (DTA)", "nonce": "2fabccb9-06b1-4670-aea8-129282e593bc", "oid": "659ea999-fd94-42b1-ad41-bfb67fdd257b", "preferred_username": "Aravinth.Sridharan@mass.gov", "rh": "0.ARwAFh2GPrdIDkqYBowE2Bt7KlN5MkR4t4VIuLzpSqkBS6ocABc.", "sub": "naDq_UiLoNjMTRjDoIBQPdYY0gTHSg0CdqjceZCtSQY", "tid": "3e861d16-48b7-4a0e-9806-8c04d81b7b2a", "uti": "_4W8cBWvAUiqpNNxyHkDAQ", "ver": "2.0", "firstname": "Aravinth", "lastname": "Sridharan" } }
    // const userInfo = { "id": 0, "userId": 139, "emailId": "Aravinth.Sridharan@mass.gov", "firstName": "aravinth", "lastName": "sridharan", "statusDetails": { "id": 702, "status": "Verified", "description": null }, "accountId": 1, "role": "NMSADN", "authorities": ["DSHBRD", "CAMVEW", "CAMCRE", "CAMCAN", "TEMVEW", "TEMUPT", "SETVEW", "SETUPT", "USRVEW", "USRCRE", "USRDEL", "OPTACS", "LOGACS", "RPTACS", "RECACS", "INVACS", "TEMDEL"] }
    // const token = { "token_type": "Bearer", "access_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjlHbW55RlBraGMzaE91UjIybXZTdmduTG83WSJ9.eyJhdWQiOiJlMjI4MWViMy00MzQ5LTQzODYtYTQ5OS05MWZmOGJhOTFmYjgiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vM2U4NjFkMTYtNDhiNy00YTBlLTk4MDYtOGMwNGQ4MWI3YjJhL3YyLjAiLCJpYXQiOjE2OTk1NTI5MTUsIm5iZiI6MTY5OTU1MjkxNSwiZXhwIjoxNjk5NTU2ODE1LCJhaW8iOiJBVFFBeS84VkFBQUFXbzlJdHMyV0FyWDk0WmExTUEyc0Y1LzRCVUIwL0RZamlaWk5VR2tzTE1VQzFNY2wyT21KUGljT01jQ25BK1ZaIiwiZW1haWwiOiJBcmF2aW50aC5TcmlkaGFyYW5AbWFzcy5nb3YiLCJuYW1lIjoiU3JpZGhhcmFuLCBBcmF2aW50aCAoRFRBKSIsIm5vbmNlIjoiOTk1MjA3N2QtMDA5NS00ZmU0LTgxYjEtMjY1YTcyMzQxYmNjIiwib2lkIjoiNjU5ZWE5OTktZmQ5NC00MmIxLWFkNDEtYmZiNjdmZGQyNTdiIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiQXJhdmludGguU3JpZGhhcmFuQG1hc3MuZ292IiwicmgiOiIwLkFSd0FGaDJHUHJkSURrcVlCb3dFMkJ0N0tyTWVLT0pKUTRaRHBKbVJfNHVwSDdnY0FCYy4iLCJzdWIiOiIxeXhOYWlkZHRrWTVjNTgyQW9kMW1CRFA3VGRqd29hVExTOWdNaUE3Wkg4IiwidGlkIjoiM2U4NjFkMTYtNDhiNy00YTBlLTk4MDYtOGMwNGQ4MWI3YjJhIiwidXRpIjoiTUN2cW1SdWJ1RUM3NUhhSnUzTllBQSIsInZlciI6IjIuMCIsImZpcnN0bmFtZSI6IkFyYXZpbnRoIiwibGFzdG5hbWUiOiJTcmlkaGFyYW4ifQ.BvqtpmikLD5BtwfjialC9BJMGZL44VUa00OK8VUrqa7566gHggKpp0oa6WVQ-rnxvSX_0SVXfnZYdL8gieqoZQAGhWHUdkuBX5DCVrDjICegBH6Soc5L6FRT0j_gJykojg2z0djlToe3n_Jw29oeS7LjKUKF1znaqubQVY7peDHff1adMfNqdYZXQPG-tpmRamCFHpOQvisnU08fF2NDNTGNh3b_-8Y5CPF9vwA0HZAVdoS8QR1S9CjMGMNVhJBrRRl296eeOUawWSK26-H6RGdTHebtblK6ti-0V0Pzh592OzOgIVzCziudfKkhn5HxGbHU7QUm6GwGFV53nIUEig" }
    // window.sessionStorage.setItem('user_name', 'Aravinth.Sridharan@mass.gov');
    // window.sessionStorage.setItem('home_account', JSON.stringify(ha));
    // window.sessionStorage.setItem('oauthToken', JSON.stringify(token));
    // window.sessionStorage.setItem('id_token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjlHbW55RlBraGMzaE91UjIybXZTdmduTG83WSJ9.eyJhdWQiOiJlMjI4MWViMy00MzQ5LTQzODYtYTQ5OS05MWZmOGJhOTFmYjgiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vM2U4NjFkMTYtNDhiNy00YTBlLTk4MDYtOGMwNGQ4MWI3YjJhL3YyLjAiLCJpYXQiOjE2OTk1NTI5MTUsIm5iZiI6MTY5OTU1MjkxNSwiZXhwIjoxNjk5NTU2ODE1LCJhaW8iOiJBVFFBeS84VkFBQUFXbzlJdHMyV0FyWDk0WmExTUEyc0Y1LzRCVUIwL0RZamlaWk5VR2tzTE1VQzFNY2wyT21KUGljT01jQ25BK1ZaIiwiZW1haWwiOiJBcmF2aW50aC5TcmlkaGFyYW5AbWFzcy5nb3YiLCJuYW1lIjoiU3JpZGhhcmFuLCBBcmF2aW50aCAoRFRBKSIsIm5vbmNlIjoiOTk1MjA3N2QtMDA5NS00ZmU0LTgxYjEtMjY1YTcyMzQxYmNjIiwib2lkIjoiNjU5ZWE5OTktZmQ5NC00MmIxLWFkNDEtYmZiNjdmZGQyNTdiIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiQXJhdmludGguU3JpZGhhcmFuQG1hc3MuZ292IiwicmgiOiIwLkFSd0FGaDJHUHJkSURrcVlCb3dFMkJ0N0tyTWVLT0pKUTRaRHBKbVJfNHVwSDdnY0FCYy4iLCJzdWIiOiIxeXhOYWlkZHRrWTVjNTgyQW9kMW1CRFA3VGRqd29hVExTOWdNaUE3Wkg4IiwidGlkIjoiM2U4NjFkMTYtNDhiNy00YTBlLTk4MDYtOGMwNGQ4MWI3YjJhIiwidXRpIjoiTUN2cW1SdWJ1RUM3NUhhSnUzTllBQSIsInZlciI6IjIuMCIsImZpcnN0bmFtZSI6IkFyYXZpbnRoIiwibGFzdG5hbWUiOiJTcmlkaGFyYW4ifQ.BvqtpmikLD5BtwfjialC9BJMGZL44VUa00OK8VUrqa7566gHggKpp0oa6WVQ-rnxvSX_0SVXfnZYdL8gieqoZQAGhWHUdkuBX5DCVrDjICegBH6Soc5L6FRT0j_gJykojg2z0djlToe3n_Jw29oeS7LjKUKF1znaqubQVY7peDHff1adMfNqdYZXQPG-tpmRamCFHpOQvisnU08fF2NDNTGNh3b_-8Y5CPF9vwA0HZAVdoS8QR1S9CjMGMNVhJBrRRl296eeOUawWSK26-H6RGdTHebtblK6ti-0V0Pzh592OzOgIVzCziudfKkhn5HxGbHU7QUm6GwGFV53nIUEig');
    // window.sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
    const ha = { "homeAccountId": "659ea999-fd94-42b1-ad41-bfb67fdd257b.3e861d16-48b7-4a0e-9806-8c04d81b7b2a", "environment": "login.windows.net", "tenantId": "3e861d16-48b7-4a0e-9806-8c04d81b7b2a", "username": "Aravinth.Sridharan@mass.gov", "localAccountId": "659ea999-fd94-42b1-ad41-bfb67fdd257b", "name": "Sridharan, Aravinth (DTA)", "idTokenClaims": { "aud": "44327953-b778-4885-b8bc-e94aa9014baa", "iss": "https://login.microsoftonline.com/3e861d16-48b7-4a0e-9806-8c04d81b7b2a/v2.0", "iat": 1692168378, "nbf": 1692168378, "exp": 1692172278, "aio": "AWQAm/8UAAAASNQwJAYGILK+xCo9VVa0f/GzhS5HcyHRH0dT8T0/1gJHTIb8eVRo1igqz/XTC7h6/64CZ9Og8rwyz780EaxQh9tr0X5n9NUSxYAyfzQpLk++1B6KZvPUMEBcBwU/QZL5", "email": "Aravinth.Sridharan@mass.gov", "name": "Sridharan, Aravinth (DTA)", "nonce": "2fabccb9-06b1-4670-aea8-129282e593bc", "oid": "659ea999-fd94-42b1-ad41-bfb67fdd257b", "preferred_username": "Aravinth.Sridharan@mass.gov", "rh": "0.ARwAFh2GPrdIDkqYBowE2Bt7KlN5MkR4t4VIuLzpSqkBS6ocABc.", "sub": "naDq_UiLoNjMTRjDoIBQPdYY0gTHSg0CdqjceZCtSQY", "tid": "3e861d16-48b7-4a0e-9806-8c04d81b7b2a", "uti": "_4W8cBWvAUiqpNNxyHkDAQ", "ver": "2.0", "firstname": "Aravinth", "lastname": "Sridharan" } }
    const userInfo = { "id": 0, "userId": 139, "emailId": "Aravinth.Sridharan@mass.gov", "firstName": "aravinth", "lastName": "sridharan", "statusDetails": { "id": 702, "status": "Verified", "description": null }, "accountId": 1, "role": "NMSADN", "authorities": ["DSHBRD", "CAMVEW", "CAMCRE", "CAMCAN", "TEMVEW", "TEMUPT", "SETVEW", "SETUPT", "USRVEW", "USRCRE", "USRDEL", "OPTACS", "LOGACS", "RPTACS", "RECACS", "INVACS", "TEMDEL"] }
    const token = { "token_type": "Bearer", "access_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IlQxU3QtZExUdnlXUmd4Ql82NzZ1OGtyWFMtSSJ9.eyJhdWQiOiI0NDMyNzk1My1iNzc4LTQ4ODUtYjhiYy1lOTRhYTkwMTRiYWEiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vM2U4NjFkMTYtNDhiNy00YTBlLTk4MDYtOGMwNGQ4MWI3YjJhL3YyLjAiLCJpYXQiOjE3MDIzMDczNjAsIm5iZiI6MTcwMjMwNzM2MCwiZXhwIjoxNzAyMzExMjYwLCJhaW8iOiJBV1FBbS84VkFBQUEzOUNhVmExdHhibzFHODVucE91Nk1pbE8rMHpQWWFlang4THU3ZjJodU5ndUkzNGtYS0hVa2JjQnlmRGVsdGpJdVY5dVVtaGdWNERWZ1I0UE9kbVhHSjZKdHlIdUdUMUliZDhnaGVpMkJXaUNzL0wyTERjbGRBRlpzSjU2bmgyYyIsImVtYWlsIjoiQXJhdmludGguU3JpZGhhcmFuQG1hc3MuZ292IiwibmFtZSI6IlNyaWRoYXJhbiwgQXJhdmludGggKERUQSkiLCJub25jZSI6ImUzMmFmNDkwLWZmMTktNGU1My05MzU5LTNiNGJhMzg2MzhhYyIsIm9pZCI6IjY1OWVhOTk5LWZkOTQtNDJiMS1hZDQxLWJmYjY3ZmRkMjU3YiIsInByZWZlcnJlZF91c2VybmFtZSI6IkFyYXZpbnRoLlNyaWRoYXJhbkBtYXNzLmdvdiIsInJoIjoiMC5BUndBRmgyR1ByZElEa3FZQm93RTJCdDdLbE41TWtSNHQ0Vkl1THpwU3FrQlM2b2NBQmMuIiwic3ViIjoibmFEcV9VaUxvTmpNVFJqRG9JQlFQZFlZMGdUSFNnMENkcWpjZVpDdFNRWSIsInRpZCI6IjNlODYxZDE2LTQ4YjctNGEwZS05ODA2LThjMDRkODFiN2IyYSIsInV0aSI6ImxnMWRNR3o3RkVLRWZfT3BBLTZBQUEiLCJ2ZXIiOiIyLjAiLCJmaXJzdG5hbWUiOiJBcmF2aW50aCIsImxhc3RuYW1lIjoiU3JpZGhhcmFuIn0.aOvsVwmLJghsOdWu8GqYSZRvmYXL3PF7qYkOVqVJZmxw63OTr5ryGDMA920nEAymcBmb_yEhMxZNLqSyll_UAxrOwL140dQ1PPlw08D1eBQila63Ht4dcco5SDfddxV1yBXy5qhCjTNZvzNyXEJ1xjS6CBpPqcwQBtLVIXFezLr5N4aWLrbs8Kcq1pQoSEOy8TL-T0way0p-LwVIQeQcyc1M2woBVWZUwPSUjkrLCHQ1b4sGx_0OmbM1vo9toW3A5yQKh-MQeGCrIkvNLkVtUFx-DQ1Fq3spHnBR-8_u2uKjASxWvk8_QYnZ5zzcOSFnfcL3OHHo4zdNUMuBAH81CQ" }
    window.sessionStorage.setItem('user_name', 'Aravinth.Sridharan@mass.gov');
    window.sessionStorage.setItem('home_account', JSON.stringify(ha));
    window.sessionStorage.setItem('oauthToken', JSON.stringify(token));
    window.sessionStorage.setItem('id_token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IlQxU3QtZExUdnlXUmd4Ql82NzZ1OGtyWFMtSSJ9.eyJhdWQiOiI0NDMyNzk1My1iNzc4LTQ4ODUtYjhiYy1lOTRhYTkwMTRiYWEiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vM2U4NjFkMTYtNDhiNy00YTBlLTk4MDYtOGMwNGQ4MWI3YjJhL3YyLjAiLCJpYXQiOjE3MDIzMDczNjAsIm5iZiI6MTcwMjMwNzM2MCwiZXhwIjoxNzAyMzExMjYwLCJhaW8iOiJBV1FBbS84VkFBQUEzOUNhVmExdHhibzFHODVucE91Nk1pbE8rMHpQWWFlang4THU3ZjJodU5ndUkzNGtYS0hVa2JjQnlmRGVsdGpJdVY5dVVtaGdWNERWZ1I0UE9kbVhHSjZKdHlIdUdUMUliZDhnaGVpMkJXaUNzL0wyTERjbGRBRlpzSjU2bmgyYyIsImVtYWlsIjoiQXJhdmludGguU3JpZGhhcmFuQG1hc3MuZ292IiwibmFtZSI6IlNyaWRoYXJhbiwgQXJhdmludGggKERUQSkiLCJub25jZSI6ImUzMmFmNDkwLWZmMTktNGU1My05MzU5LTNiNGJhMzg2MzhhYyIsIm9pZCI6IjY1OWVhOTk5LWZkOTQtNDJiMS1hZDQxLWJmYjY3ZmRkMjU3YiIsInByZWZlcnJlZF91c2VybmFtZSI6IkFyYXZpbnRoLlNyaWRoYXJhbkBtYXNzLmdvdiIsInJoIjoiMC5BUndBRmgyR1ByZElEa3FZQm93RTJCdDdLbE41TWtSNHQ0Vkl1THpwU3FrQlM2b2NBQmMuIiwic3ViIjoibmFEcV9VaUxvTmpNVFJqRG9JQlFQZFlZMGdUSFNnMENkcWpjZVpDdFNRWSIsInRpZCI6IjNlODYxZDE2LTQ4YjctNGEwZS05ODA2LThjMDRkODFiN2IyYSIsInV0aSI6ImxnMWRNR3o3RkVLRWZfT3BBLTZBQUEiLCJ2ZXIiOiIyLjAiLCJmaXJzdG5hbWUiOiJBcmF2aW50aCIsImxhc3RuYW1lIjoiU3JpZGhhcmFuIn0.aOvsVwmLJghsOdWu8GqYSZRvmYXL3PF7qYkOVqVJZmxw63OTr5ryGDMA920nEAymcBmb_yEhMxZNLqSyll_UAxrOwL140dQ1PPlw08D1eBQila63Ht4dcco5SDfddxV1yBXy5qhCjTNZvzNyXEJ1xjS6CBpPqcwQBtLVIXFezLr5N4aWLrbs8Kcq1pQoSEOy8TL-T0way0p-LwVIQeQcyc1M2woBVWZUwPSUjkrLCHQ1b4sGx_0OmbM1vo9toW3A5yQKh-MQeGCrIkvNLkVtUFx-DQ1Fq3spHnBR-8_u2uKjASxWvk8_QYnZ5zzcOSFnfcL3OHHo4zdNUMuBAH81CQ');
    getClientInfo(msalIns);
  }

  const handleChange = () => {
    setChecked((prev) => !prev)
  }


  return (
    <>
    <Backdrop
     sx={{ color: '#fff', zIndex:  1000 }}
     open={authDetails.isLoading}
    >
    <SpinningLoader circleColor={appConfig.colors.white} label="Logging In" />
    </Backdrop>
    <Grid className='main-banner-wrapper' role="main">
      {/* <Grid container className="green-wrapper">
        <Grid container className="wrapper-banner">
          <Grid item xs={12} md={12} lg={12} display={{ xs: 'none', lg: 'block', md: 'block' }} className="banner div-center"
            onClick={handleChange}>
            <Grid item xs={12} md={12} lg={12} display="flex" alignItems="center" >
              <img src={brandLogo} alt="Massachusetts State Seal" className="banner-logo-dark" />&nbsp;
              <div className="banner-text div-center font-state-banner">
                <MaterialLink style={{ color: 'white' }} className="">
                  <span >An official website of the Commonwealth of Massachusetts&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <span className="button-icon--quaternary">
                    <b className="font-state-how">Here's how you know</b>&nbsp;&nbsp;
                    <img src={UpArrow} alt={!checked ? 'Down' : 'Up'} width="15" className={!checked ? 'img-animation img-updown' : 'img-downup'} />
                  </span>
                </MaterialLink>
              </div>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={12} lg={12} display={{ xs: 'block', lg: 'none', md: 'none' }}
            className="cursor-pointer div-center" onClick={handleChange}>
            <Grid item xs={12} md={12} lg={12} display="flex" alignItems="center">
              <img src={brandLogo} alt="Massachusetts State Seal logo" className="banner-logo-dark" />
              <p className="banner-text font-state-banner">
                An official website of the Commonwealth of Massachusetts &nbsp;&nbsp;&nbsp;
                <b className="font-state-how"> Here's how you know </b>&nbsp;&nbsp;
                <img src={UpArrow} alt={!checked ? 'Down' : 'Up'} width="15" className={!checked ? 'img-animation img-updown' : 'img-downup'} />
              </p>
            </Grid>

          </Grid>
          <Collapse in={checked}>
            <Grid container>
              <Grid item xs={12} lg={5} md={5} sm={12}>
                <div className='green-banner-contents-wrapper'>
                  <div className='mass-gov-banner-icon'> <img alt="Government building logo" src={GovtIcon} height="32" /></div>
                  <div className='green-banner-contents'>
                    <span className="font-state-banner" style={{ fontWeight: '550' }}>Official websites use .mass.gov</span>
                    <p className="font-state-banner m0">A .mass.gov website belongs to an official government organization in Massachusetts.</p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} lg={6} md={6} sm={12}>
                <div className='green-banner-contents-wrapper'>
                  <div className='mass-gov-banner-icon'> <img alt="HTTPS logo" src={LockLarge} height="32" /></div>
                  <div className='green-banner-contents'>
                    <span className="font-state-banner" style={{ fontWeight: '550' }}>Secure websites use HTTPS certificate</span>
                    <p className="font-state-banner m0" >A lock icon (&nbsp;<img src={LockSmall} alt="" />&nbsp;) or https:// means you've safely connected to the official website. Share sensitive information only on official, secure websites.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid> */}
      <Grid className='blue-wrapper' sx={{ boxShadow: '0 2px 10px 0 ' + appConfig.colors.grey }}>
        <Grid className='wrapper-banner'>
          <Grid container item display="flex" xl={12} lg={12} md={12}>
            <Grid item className="home-logo" display="flex" xs={3} lg={7.5} md={7.5}>
              {/* logo and main header text */}
              <div className="main-logo">
                <MaterialLink to="/home" >
                  <img src={brandLogo} height="45" alt="Falcon Logo" />
                  {/* <h1 className="logo-text" data-testid="logo-text">FALCON</h1> */}
                </MaterialLink>
              </div>
            </Grid>

            <Grid item xs={9} lg={4.5} md={4.5} sx={[appConfig.height44]} className="div-center lang-dp" justifyContent="flex-end">
              <div aria-labelledby='login'>
                <Styled.CampaignButton
                  className='text-trans-none'
                  variant="contained"
                  onClick={() => { bypassLogin(instance) }}
                >
                  Log In
                </Styled.CampaignButton>
              </div>
            </Grid>
          </Grid>
          <Grid container >
        <Grid className="top-paper-wrapper" >
          <Paper sx={{ p: 3, color: appConfig.colors.black }} className="box-show-dark slider-paper top-paper">
            <p className="create-box-content" style={{ marginTop: '0' }}>Log In to <b>K Notify</b> platform to</p>
            <ul style={{ paddingLeft: '18px' }}>
              <li>Create and administer Email, SMS, and voice campaigns</li>
              <li>View operational reports on existing campaigns</li>
              <li>Trigger instant SMS, Email, and voice notifications</li>
            </ul>
            <p className="create-box-content mt-1">If you do not have access to the platform, please contact IT support. </p>
          </Paper>
        </Grid>
      </Grid>
        </Grid>

      </Grid>
      
    </Grid>
    </>
  );
}


const mapStateToProps = (state) => ({ authDetails: state.auth })

const mapDispatchToProps = (dispatch) => {
  return {
    azureLogOut: (instance) => dispatch(signInActions.azureLogOut(instance)),
    getClientInfo: (msal) => dispatch(signInActions.getClientInfo(msal)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);


SignIn.propTypes = {
  azureLogOut: PropTypes.func.isRequired,
  getClientInfo: PropTypes.func.isRequired,
  authDetails: PropTypes.arrayOf(PropTypes.array).isRequired,
}