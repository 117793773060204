import * as chatConstants from './actionTypes';
import { config } from '../../config/config';
import request from '../../components/Helper/Request';

export function getChatList() {
  return async (dispatch) => {
    dispatch({
      type: chatConstants.GET_CHAT_LIST,
    });

    try {
      const response = await request({
        method: 'get',
        // headers:{
        //     "ngrok-skip-browser-warning": "1234"
        //   },
        url: `${config.API_URL}/api/optout/conversation`,
        // url: 'https://d59a-111-92-21-80.ngrok-free.app/api/optout/conversation'
      });

      dispatch({
        type: chatConstants.GET_CHAT_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: chatConstants.GET_CHAT_LIST_FAILURE,
        payload: error,
      });
    }
  };
}
export function getPhoneNumberList() {
    return async (dispatch) => {
      dispatch({
        type: chatConstants.GET_CHAT_PHONE_LIST,
      });
  
      try {
        const response = await request({
          method: 'get',
          // headers:{
          //   "ngrok-skip-browser-warning": "1234"
          // },
          url: `${config.API_URL}/api/optout/phno`,
          // url: `https://d59a-111-92-21-80.ngrok-free.app/api/optout/phno`,
        });
  
        dispatch({
          type: chatConstants.GET_CHAT_PHONE_LIST_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: chatConstants.GET_CHAT_PHONE_LIST_FAILURE,
          payload: error,
        });
      }
    };
  }
  export function sendOutBoundMessage(payload) {
    return async (dispatch) => {
      dispatch({
        type: chatConstants.SEND_OUTBOUND_MSG,
      });
  
      try {
        const response = await request({
          method: 'post',
          // headers:{
          //   "ngrok-skip-browser-warning": "1234"
          // },
          data: payload,
          url: `${config.API_URL}/api/optout/outbound`,
          // url: `https://d59a-111-92-21-80.ngrok-free.app/api/optout/outbound`,
        });

        dispatch({
          type: chatConstants.SEND_OUTBOUND_MSG_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: chatConstants.SEND_OUTBOUND_MSG_FAILURE,
          payload: error,
        });
      }
    };
  }
