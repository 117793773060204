import React, { useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PropTypes from 'prop-types';
import textFormatting from "./TextFormatting";
import appConfig from "../config/appConfig";

const FilterDropDown = ({
  onSelect,
  selectOptions,
  selectedValue = selectOptions[0].key,
  isEnableFirstItem=true,
  disabled,
  dropdownWidth = "inherit",
  dropdownHeight = "inherit",
  textAlign = "center",
  icon
}) => { 
  const dropdownRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (value) => {
    setAnchorEl(null);
    onSelect(value);
  };
  const handleClickOutside = (event) => {
    const path = event.path || (event.composedPath && event.composedPath());
    if (!path.includes(dropdownRef.current)) {
      setAnchorEl(null);
    }
  };
  useEffect(() => {
    // Assign click handler to listen the click to close the dropdown when clicked outside
    document.addEventListener("click", handleClickOutside);
  }, []);

  return (
    <div className='inline-blk' ref={dropdownRef}>
      <Button
        disabled={disabled}
        aria-controls={open ? "filtermenu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={icon || < KeyboardArrowDownIcon/>}
        style={{ justifyContent: textAlign, background: disabled ? "#eee" : "#fff", color: 'black' , padding: "4px 10px", border: "1px solid black", width: dropdownWidth, textTransform: 'none', height: dropdownHeight }}
        sx={{
          fontFamily: appConfig.fontfamily,
          // fontSize:appConfig.font16,
        }}
      >
        {typeof selectedValue === "string" ? textFormatting(selectedValue.key) : selectedValue.key}
      </Button>
      <Menu
        id="filtermenu"
        MenuListProps={{
          "aria-labelledby": "filtermenu-button"
        }}
        anchorEl={anchorEl}
        open={open}
        sx={{ '& .MuiMenu-list': { minWidth: "150px" } }}
      >

        {selectOptions.map((option,index) => <MenuItem
          sx={{
            fontFamily: appConfig.fontFamily,
            display: index === 0 && !isEnableFirstItem  ? "none" : "block",
            color: 'black',
          }}
          key={option.key}
          onClick={() => {
            handleClose(option);
          }}
          disableRipple
        >
          {typeof option.key === "string" ? textFormatting(option.key) : option.key}
        </MenuItem>)}
      </Menu>
    </div>
  );
}


export default FilterDropDown;


FilterDropDown.defaultProps = {
  disabled: false,
  // selectedValue: "",
  isEnableFirstItem: true,
  dropdownWidth: "inherit",
  dropdownHeight: "inherit",
  textAlign: "center",
  selectedValue: {},
  icon:null
};

FilterDropDown.propTypes = {
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  selectOptions: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])).isRequired,
  isEnableFirstItem: PropTypes.bool,
  selectedValue: PropTypes.objectOf(PropTypes.object),
  dropdownWidth: PropTypes.string,
  dropdownHeight: PropTypes.string,
  textAlign: PropTypes.string,
  icon: PropTypes.object,
}
