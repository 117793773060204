import * as actionTypes from './actionTypes';
import {config,postLogoutRedirectUri,msalConfig } from '../../config/config';


import request from '../../components/Helper/Request';
import { setCookie } from '../../components/Helper/cookies';


export  function getClientInfo(msalIns) {
    const username = window.sessionStorage.getItem('user_name');
    const data = {
        "emailId": username,
        "password": "",
      }
    return (dispatch) => {
      dispatch({
        type: actionTypes.AZURE_SIGN_IN
      });

      request({
        method: 'post',
        url: `${config.API_URL}/api/login/user`,
        data
      }).then((response) => {
        sessionStorage.setItem("userInfo", JSON.stringify(response.data));
        dispatch({
          type: actionTypes.AZURE_SIGN_IN_SUCCESS,
          payload: response.data 
        });
        
      }).catch( (error) => {
        console.log(error)
            setCookie('isLoginFailed',true);
            const currentAccount = msalIns.getAccountByHomeId(JSON.parse(window.sessionStorage.getItem('home_account')).homeAccountId);
            msalIns.logout({
                account: currentAccount,
                postLogoutRedirectUri: postLogoutRedirectUri.redirectUri,
                authority: msalConfig.auth.authority
              });
              window.sessionStorage.clear();
          dispatch({
            type: actionTypes.AZURE_SIGN_IN_FAILURE, 
            payload: error 
          });
      })
    
    }
}



export  function azureLogOut(msalInstance) {
    if(window.sessionStorage.getItem('home_account')){
      const currentAccount =  msalInstance.getAccountByHomeId(JSON.parse(window.sessionStorage.getItem('home_account')).homeAccountId);	
        msalInstance.logoutRedirect({	
        account: currentAccount,	
        postLogoutRedirectUri: postLogoutRedirectUri.redirectUri,	
        authority: msalConfig.auth.authority	
      });	
    }else{
        msalInstance.logoutRedirect({	
        postLogoutRedirectUri: postLogoutRedirectUri.redirectUri,	
        authority: msalConfig.auth.authority	
      });	
    }
    window.sessionStorage.setItem('isLogout',true);
  }