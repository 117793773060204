import * as actionTypes from '../actions/actionTypes';

const initialState = {
    chatList: { 
        chatList: "",
        isLoading: false,
        isError: false
    },
    phoneNumberList: { 
        phoneNumberList: "",
        isLoading: false,
        isError: false
    },
    outboundMsg: {
        isLoading: false,
        isError: false,
        data:"",
    },
  isLoading: false,
  isError: false,
}

const usersReducer = (state = initialState, action = {}) => {

  const newState = { ...state };

  switch (action.type) {
    case actionTypes.GET_CHAT_LIST:
        return { ...newState, chatList: { ...newState.chatList, isLoading: true } };

    case actionTypes.GET_CHAT_LIST_SUCCESS:
        return { ...newState, chatList: { chatList: action.payload, isLoading: false,isError: false}};

    case actionTypes.GET_CHAT_LIST_FAILURE:
        return { ...newState, chatList: { chatList: action.payload, isLoading: false,isError: true}};

    case actionTypes.GET_CHAT_PHONE_LIST:
        return { ...newState, phoneNumberList: { ...newState.phoneNumberList, isLoading: true } };

    case actionTypes.GET_CHAT_PHONE_LIST_SUCCESS:
        return { ...newState, phoneNumberList: { phoneNumberList: action.payload, isLoading: false,isError: false}};

    case actionTypes.GET_CHAT_PHONE_LIST_FAILURE:
        return { ...newState, phoneNumberList: { phoneNumberList: action.payload, isLoading: false,isError: true}};
    
    case actionTypes.SEND_OUTBOUND_MSG:
        return { ...newState, outboundMsg: { ...newState.outboundMsg, isLoading: true } };

    case actionTypes.SEND_OUTBOUND_MSG_SUCCESS:
        return { ...newState, outboundMsg: {data:{...action.payload},isLoading: false, isError: false} };

    case actionTypes.SEND_OUTBOUND_MSG_FAILURE:
        return { ...newState, outboundMsg: {data:{...action.payload},isLoading: false, isError: true} };
    default:
      return { ...newState };
  }
};


export default usersReducer;