import * as actionTypes from '../actions/actionTypes';

const initialState = {
   audits:{
    isLoading: false,
    isError: false,
    data:{},
    totalCount:0
   }
  }

  const auditReducer = (state = initialState, action = {}) => {

    const newState = { ...state };
  
    switch (action.type) {
      case actionTypes.FETCH_AUDITS:
        return { ...newState, audits: { ...newState.audits, isLoading: true } };
  
      case actionTypes.FETCH_AUDITS_SUCCESS:
        return { ...newState, audits: {data:{...action.payload},isLoading: false, isError: false,totalCount: action.payload?.totalCount} };

      case actionTypes.FETCH_AUDITS_FAILURE:
        return { ...newState, audits: {data:{...action.payload},isLoading: false, isError: true} };
  
      default:
        return { ...newState };
    }
  };
  
  
  export default auditReducer;