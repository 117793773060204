import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, TextField, Backdrop } from '@mui/material';
import { useForm } from 'react-hook-form';
import RadioButtonGroup from '../../components/RadioButtonGroup';
import * as Styled from '../../components/styled-components/Campaign';
import * as instantCampaignActions from '../../store/actions/instantCampaign';
import SpinningLoader from '../../components/SpinningLoader';

import appConfig from '../../config/appConfig';

const userDetails = JSON.parse(window.sessionStorage.getItem("userInfo"));


const textAreaCss = {
    resize: "none",
    width: "100%",
    outline: "none",
    borderRadius: "4px",
    border: `1px solid ${appConfig.colors.darkblue}`,
    height: "100px",
    fontFamily: `${appConfig.fontfamily}`,
    fontSize: "16px",
    padding: "5px",
}

const ErrorStyle = {
    color: "red",
    display: 'flex',
    marginTop: '5px'
}

const textboxCss = {
    border: `1px solid ${appConfig.colors.darkblue}`,
    borderRadius: "4px",
    outline: "none",
    '&.MuiFormControl-root': {
        margin: "0"
    },
    '&.MuiTextField-root': {
        backgroundColor: '#fff'
    },
    '& .MuiInputBase-input': {
        backgroundColor: '#fff',
        fontSize: 16,
        fontFamily: appConfig.fontfamily,
        padding: "10px 14px",
        borderRadius: "4px",
        outline: "none",

    },
    '& .MuiOutlinedInput-root': {
        padding: '0',
    }
};

const Broadcast = ({ createInstantCampaign, instantCampaignData }) => {

    const [channel, setChannel] = useState("SMS");
    const [isLoading, setIsLoading] = useState(false);
    const [isCampaignPublished, setIsCampaignPublished] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();


    const handleChannelChange = (event) => {
        const channelVal = (event.target.value)?.toLowerCase();
        setChannel(channelVal);
    };

    const onSubmit = (data) => {

        const broadcastData = {
            name: data.name,
            sendTo: data.contacts.split(','),
            content: data.content,
            channel,
            subject: data.subject || "",
            createdBy: userDetails?.emailId,
            accountId: userDetails?.accountId
        }

        const swalWithCustompButtons = Swal.mixin({
            customClass: {
                confirmButton: 'custom-ok-button',
                cancelButton: 'custom-cancel-button'
            },
            buttonsStyling: false
        })

        swalWithCustompButtons.fire({
            title: "",
            text: 'Send instant notification?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Ok',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true);
                setIsCampaignPublished(true);
                const broadcastDataCopy = {...broadcastData};
                const broadcastDataFinal = broadcastDataCopy.sendTo.map(recipient => recipient.trim().split(' ').join('')).filter((oneRec)=> oneRec.length > 0);
                broadcastDataCopy.sendTo = broadcastDataFinal;
                createInstantCampaign(broadcastDataCopy)
            }
        })


    }

    useEffect(() => {
        if (instantCampaignData && instantCampaignData.data.status === 1 && isCampaignPublished) {
            setIsLoading(false);
            setIsCampaignPublished(false);
            Swal.fire({
                icon: "success",
                button: false,
                showConfirmButton: false,
                timer: 2500,
                text: "Notification created successfully",
            });
            setTimeout(() => {
                navigate("/instant");
            }, 1500)

        } else if (instantCampaignData.data.status === 0 || instantCampaignData.isError && isCampaignPublished) {
            setIsLoading(false);
            setIsCampaignPublished(false);
            Swal.fire({
                icon: "error",
                button: false,
                showConfirmButton: false,
                timer: 2500,
                text: "Error while creating campaign. Please try again",
            });
        }
    }, [instantCampaignData.data.status, instantCampaignData.isLoading, instantCampaignData.isError])

    return <div>

        {isLoading ? <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <SpinningLoader circleColor={appConfig.colors.darkblue} label="Publishing Campaign" />
        </Backdrop> : <>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
                <h1 className='page-heading' ><b>Create Instant Notifications</b></h1>
                </Grid>
            </Grid>
            <hr style={{ border: "3px solid #EDF6F5", margin: "20px 0" }} />
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12} xl={12} lg={12} md={12}>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <Grid item md={7} xl={7} lg={7} sm={12} xs={12} sx={{ mb: 2 }}>
                            <Typography component="p" sx={{ mb: 1 }}>Notification Name (Required)</Typography>
                            <TextField
                                {...register('name', { required: true, pattern: /^[A-Za-z ]+$/,maxLength: 50 })}
                                margin="normal"
                                id="name"
                                sx={textboxCss}
                                fullWidth
                            />
                        {errors?.name?.type === "required" && <span style={ErrorStyle}>Please enter notification name.</span>}
                        {errors?.name?.type === "pattern" && (<span style={ErrorStyle}>Numbers / Special characters not allowed.</span>)}
                        {errors?.name?.type === "maxLength" && (<span style={ErrorStyle}>Notification name cannot exceed 50 characters</span>)}                        </Grid>

                        <Grid item xl={7} md={7} lg={7} sm={12} xs={12} sx={{ mb: 2 }}>
                            <Typography component="p" sx={{ mt: 2, mb: 2 }}>Communication Channel</Typography>
                            <RadioButtonGroup
                                header=' '
                                // className={['create-using-account-radio', channel === "email" ? "email" : "sms"].join(' ')}
                                value={channel}
                                labels={['SMS', 'Email', 'Voice']}
                                handleChange={handleChannelChange}
                            />
                        </Grid>

                        <Grid item md={7} xl={7} lg={7} sm={12} xs={12} sx={{ mb: 2 }}>
                            <Typography component="span" sx={{ mb: 1 }}>Contacts (Required)</Typography>
                            <Typography component="span" sx={{ mb: 1, float: "right" }}>Comma Separated List of Phone Numbers / Email</Typography>
                            <textarea
                                style={textAreaCss}
                                id="content"
                                {...register('contacts', { required: true })}
                            />
                            {errors?.contacts && <span style={ErrorStyle}>Please enter contacts.</span>}
                        </Grid>
                        {channel.toLowerCase() === "email" &&
                            <Grid item md={7} xl={7} lg={7} sm={12} xs={12} sx={{ mb: 2 }}>
                                <Typography component="p" sx={{ mb: 1 }}>Subject (Required)</Typography>
                                <TextField
                                    {...register('subject', { required: channel.toLowerCase() === "email" })}
                                    margin="normal"
                                    id="name"
                                    sx={textboxCss}
                                    fullWidth
                                />
                                {errors?.subject?.type === "required" && <span style={ErrorStyle}>Please enter subject.</span>}
                            </Grid>
                        }

                        <Grid item md={7} xl={7} lg={7} sm={12} xs={12} >
                            <Typography component="span" sx={{ mb: 1 }}>Message Content (Required)</Typography>
                            <Typography component="span" sx={{ mb: 1, float: "right" }}>Recommended characters: 750</Typography>
                            <textarea
                                style={textAreaCss}
                                id="content"
                                {...register('content', { required: true })}
                            />
                            {errors?.content && <span style={ErrorStyle}>Please enter content.</span>}
                        </Grid>
                        <Grid container spacing={2}  >
                        <Grid item xs={12} sm={4} xl={2} lg={2} md={2}>
                        <Styled.CancelButton
                            variant="outlined"
                            sx={{ mt: 3, ml: 1, fontFamily: appConfig.fontfamily, textTransform: 'capitalize',width:"100%", height: "50px" }}
                            color="primary"
                            onClick={() => {
                                navigate('/instant')
                            }}
                        >
                            Cancel
                        </Styled.CancelButton>
                    </Grid>
                            <Grid item xs={12} sm={8} xl={5} lg={5} md={5}>
                                <Styled.CampaignButton
                                    type='submit'
                                    variant="contained"
                                    sx={{ mt: 3, fontFamily: appConfig.fontfamily, textTransform: 'capitalize', width: '100%', height: "50px" }}
                                >
                                    Create
                                </Styled.CampaignButton>
                            </Grid>
                        </Grid>

                    </form>
                </Grid>
            </Grid>


        </>}
    </div>




}




const mapStateToProps = (state) => ({ instantCampaignData: state.instantCampaign.instantCampaign })

const mapDispatchToProps = (dispatch) => {
    return {
        createInstantCampaign: (data) => dispatch(instantCampaignActions.createInstantCampaign(data)),
    }
}

Broadcast.propTypes = {
    createInstantCampaign: PropTypes.func.isRequired,
    instantCampaignData: PropTypes.object.isRequired
}


export default connect(mapStateToProps, mapDispatchToProps)(Broadcast);

