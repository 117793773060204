import React, { useEffect, useState, useRef } from 'react';
import { Grid } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import { useNavigate } from 'react-router-dom'; // Link,
import Swal from "sweetalert2";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DefaultTable from '../components/Table';
import * as templateActions from '../store/actions/template';
import * as settingsActions from '../store/actions/settings';
import * as menuActions from '../store/actions/breadcrumb';
import * as Styled from '../components/styled-components/Campaign';
import SkeletonGridLoader from '../components/SkeletonGridLoader';
import SpinningLoader from '../components/SpinningLoader';
import '../styles/App.scss';


import { CalculateUTCTzToUserTz, retriveLanguage, splitDate, splitTime, formatDTADate, getChannelLabel} from '../components/Utils';
import {config} from '../config/config';
import appConfig from '../config/appConfig';


const Template = ({ fetchTemplateList, templateDetails, SetEditTemplateData, setBreadcrumb,deleteTemplate,resetTemplateConfiguration}) => {
    const snapshotRef = useRef(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [enableRowDetails, setEnableRowDetails] = useState(false);
    const [isTemplateDeleted, setIsTemplateDeleted] = useState(false);
    const [templateId, setTemplateId] = useState(null);
    const [pagination, setPagination] = useState({ limit: 10, offset: 1 });
    const [selected, setSelected] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [templateFilter,setTemplateFilter] = useState( {key:"All Communication Channels", value:"all"});
    const isMounted = useRef(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sortValue, setSortValue] = useState({sortBy:"modifiedTime", sortOrder: "desc"});

    const navigate = useNavigate();
    const userDetails = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const filterValues = {
      "templateFilter": templateFilter,
      "sortValue":sortValue
      // startDate: CalculateUserTzToUTCTz(selectedDate?.startDate).toISOString(),
      // endDate: CalculateUserTzToUTCTz(endOfDay(selectedDate?.endDate)).toISOString()
    }

    useEffect(() => {
      setBreadcrumb('Templates')
        resetTemplateConfiguration()
        if(templateDetails.templates.length)
        fetchTemplateList(pagination.limit, pagination.offset,"",filterValues);
    },[])

    const handleSearch = (search) =>{
      isMounted.current = false;
        setSearchText(search);
    }


    useEffect(()=>{
      isMounted.current = true;
        fetchTemplateList(pagination.limit, pagination.offset,searchText,filterValues);
    },[JSON.stringify(pagination),templateFilter,JSON.stringify(sortValue)])

    useEffect(()=>{
      if (!isMounted.current) {
      setPagination({limit:pagination.limit,offset:1})
      fetchTemplateList(pagination.limit, 1,searchText,filterValues);
      }
  },[searchText])

  
  useEffect(() => {
    if (templateDetails?.deleteTemplateData?.data?.status === 1 && isTemplateDeleted) {
      setIsTemplateDeleted(false);
      setIsLoading(false);
      Swal.fire({
        icon: "success",
        button: false,
        showConfirmButton: false,
        timer: 2000,
        text: "Template deleted successfully",
      });
      setTimeout(() => {
        fetchTemplateList(pagination.limit, 1,searchText,filterValues);
      }, 2000);
    }else if(templateDetails?.deleteTemplateData?.data?.status === 0 && isTemplateDeleted){
      setIsTemplateDeleted(false);
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        button: false,
        showConfirmButton: false,
        timer: 2000,
        text: "Error, please try again later",
      });
    }
  }, [JSON.stringify(templateDetails?.deleteTemplateData)])


    const handlePagination = (limit, offset) => {
        setPagination({ limit, offset })
    }

    const handleEditTemplate = (selectedT) => {
        SetEditTemplateData(selectedT);
        navigate("/edittemplate");
    }

    const handleDeleteTemplate = (selectedTemp) => {
    const swalWithCustompButtons = Swal.mixin({
      customClass: {
        confirmButton: 'custom-ok-button',
        cancelButton: 'custom-cancel-button'
      },
      buttonsStyling: false
    })

    swalWithCustompButtons.fire({
      title: "",
      text: 'Delete template?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Delete',
      reverseButtons: true
    }).then((result) => {
      setIsTemplateDeleted(false);
      if (result.isConfirmed) {
        setIsTemplateDeleted(true);
        setIsLoading(true);
        deleteTemplate({id: selectedTemp.id, modifiedBy:sessionStorage.getItem('user_name')});
      } else {
        setIsTemplateDeleted(false);
      }
    })
  }

  const loadRowDetails = () => {
    return (
        <Grid container spacing={2} padding={1}>
            <Grid item xs={12} lg={12} md={12} >
                <div>
                <div className='data-grid'>
          <span style={{color:appConfig.colors.darkblue,fontFamily:"Source Sans Pro",fontSize:appConfig.font16}}><b>Associated Campaign: </b></span>
          <span>{selectedTemplate?.campaignDetails?.campaignName}</span>
        </div>
                <p style={{color:appConfig.colors.darkblue,fontFamily:"Source Sans Pro",fontSize:appConfig.font16}}>
                    <b>Template Content:</b>
                </p>
                </div>
            </Grid>
            
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

            <table style={selectedTemplate?.channel.toLowerCase() !== "email" ? {width:"70%"} : null} className='content-table'>
        <thead>
        <th className='content-th'>Language</th>
        {selectedTemplate.channel.toLowerCase() === "email" ? <th className='content-th'>Subject</th> : null}
        <th className='content-th'>Content</th>
        </thead>
        <tbody>
          {selectedTemplate.templateDetails.map((item) => (
            <tr>
               <td className='content-td'>{retriveLanguage(item?.language)}</td>
               {selectedTemplate.channel.toLowerCase() === "email" ? <td className='content-td'>{item?.subject !== null ? item?.subject: "-"}</td> : null}
               {selectedTemplate.channel.toLowerCase() === "email" ? <td className='content-td'><div style={{width:"100%",wordBreak:"break-word"}} dangerouslySetInnerHTML={{__html: item?.content}}/></td> :
               <td className='content-td' style={{width:"100%",wordBreak:"break-word"}}>{item?.content}</td> }
            </tr>
          ))}
        </tbody>
      </table>
            </Grid>
        </Grid>
    );
  }

  const renderActionButton = (row) => {
    return <div className='display-flex'>
                { userDetails?.authorities.includes('TEMUPT') ? 
            <IconButton sx={{border:`1px solid ${appConfig.colors.darkblue}`, padding:'2px' }} onClick={() => { 
                handleEditTemplate(row);
                }}
                title='Edit Template' aria-label="delete" size="small">
               <ModeEditOutlineOutlinedIcon sx={{fontSize: 24, borderRadius:"50%", color:appConfig.colors.darkblue}} />
            </IconButton> : null }
            { userDetails?.authorities.includes('TEMDEL') ? 
            <IconButton sx={{border:`1px solid ${appConfig.colors.darkblue}`, padding:'2px', marginLeft:"20px" }} onClick={() => { 
                handleDeleteTemplate(row);
                }}
                title='Delete Template' aria-label="delete" size="small">
               <DeleteForeverOutlinedIcon sx={{fontSize: 24, borderRadius:"50%", color:appConfig.colors.darkblue}} />
            </IconButton> : null }
    </div>
  }


  const renderDetailsButton = (row) => {
    return <div>
            <IconButton className='pad-0' onClick={() => {
                setSelectedTemplate(row);
                setEnableRowDetails( templateId !== row.id ? true : !enableRowDetails);
                setTemplateId(row.id);
                setSelected(row.id)
                setTimeout(()=>{
                    snapshotRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                },500);
            }} title='View Details' aria-label="delete" size="small">
               {templateId === row.id  && enableRowDetails ? <KeyboardArrowUpOutlinedIcon sx={{border:`1px solid ${appConfig.colors.darkblue}`,fontSize: 30, borderRadius:"50%", color:appConfig.colors.darkblue}}  /> :<KeyboardArrowDownOutlinedIcon sx={{border:`1px solid ${appConfig.colors.darkblue}`,fontSize: 30, borderRadius:"50%", color:appConfig.colors.darkblue}}  fontSize="inherit" />}
            </IconButton>
    </div>
  }

  const getFilterValues = (filter) =>{
    setPagination((prevState)=>({
        ...prevState,
        offset: 1
    }));
    setTemplateFilter(filter);
  }

  const handleSort = (value) => {
    setSortValue(value)
    }

  const renderCells = (row) =>{
    return <>
    <TableCell sx={{fontFamily:appConfig.fontfamily,fontSize:appConfig.font16}}>{row.name}</TableCell>
    <TableCell sx={{fontFamily:appConfig.fontfamily,fontSize:appConfig.font16}}>{row.code}</TableCell>
    <TableCell sx={{fontFamily:appConfig.fontfamily,fontSize:appConfig.font16}}>{row.channel? getChannelLabel(row.channel) : "-"}</TableCell>
    <TableCell sx={{fontFamily:appConfig.fontfamily,fontSize:appConfig.font16}}>{formatDTADate(splitDate(CalculateUTCTzToUserTz(row.createdTime)))} {splitTime(CalculateUTCTzToUserTz(row.createdTime))}</TableCell>
    <TableCell sx={{fontFamily:appConfig.fontfamily,fontSize:appConfig.font16}}>  {row.modifiedBy} <br/> {formatDTADate(splitDate(CalculateUTCTzToUserTz(row.modifiedTime)))} {splitTime(CalculateUTCTzToUserTz(row.modifiedTime))} </TableCell>
    </> 
  }

  const onRefresh = () => {
    setPagination({offset:1,limit:10})
    setSearchText("");
    setTemplateFilter({ key: 'All Communication Channels', value: "all" })
   }

  return (
    <div>
        {isLoading ? <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <SpinningLoader circleColor={appConfig.colors.darkblue} label="loading..." />
        </Backdrop> : <>
        <Grid container spacing={2}>
        <Grid item xs={6} sm={6} xl={6} lg={6} md={6}>
        <h1 className='page-heading' ><b>Templates</b></h1>
          </Grid>
          <Grid item xs={6} xl={6} lg={6} md={6}>
          { userDetails?.authorities.includes('TEMUPT') ? 
            <div className='text-align-right'>
              <Styled.CampaignButton
                className='text-trans-none'
                variant="contained"
                onClick={()=>navigate('/createtemplate', {state:{initState:true}})}
                >
                Create Template
              </Styled.CampaignButton>
            </div> 
            : null } 
          </Grid>
        </Grid>
        <hr style={{border:"3px solid #EDF6F5", margin:"20px 0"}}/>
        {templateDetails?.templates?.isLoading ? <SkeletonGridLoader />
          :
          <DefaultTable
            title='Templates'
            handlePagination={handlePagination}
            headers={[{name:'Template Name', sortEnabled:false},{name:'Code', sortEnabled:false},{name:'Channel', sortEnabled:false},{name:'Created Date & Time', sortEnabled:true,sortBy:'createdTime'},{name:'Last Updated', sortEnabled:true,sortBy:'modifiedTime'}]}
            hasAction
            renderActionButton={renderActionButton}
            hasDetails
            data={templateDetails.templates ? templateDetails.templates.templates : []}
            totalCount={templateDetails.templates?.count}
            renderDetailsButton={renderDetailsButton}
            currentPage={pagination.offset}
            currentLimit={pagination.limit}
            selected = {selected}
            loadRowDetails = {enableRowDetails ? loadRowDetails() : null}
            enableRowDetails = {enableRowDetails}
            isSearchEnabled
            searchKey={searchText}
            searchCallback = {(text)=>handleSearch(text)}
            searchPlaceholderText = "Search by template name/code"
            isFilterEnabled
            filterAction={(filter)=>{getFilterValues(filter)}} 
            filterOptions= {config.CHANNEL_LIST}
            defaultFilter={templateFilter}
            renderCustomCells
            renderCells = {renderCells}
            isRefreshEnabled
            refreshCallback={() => { onRefresh() }}
            isSortEnabled
            handleSort={handleSort}
            sortValue={sortValue}
            />
            
          }
          </>}
          
    </div>    
  );
}

const mapStateToProps = (state) => ({ templateDetails: state.template })

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTemplateList: (limit,page,searchText,templateFilter) => dispatch(templateActions.getTemplateList(limit,page,searchText,templateFilter)),
    SetEditTemplateData : (data) => dispatch(templateActions.setEditTemplateData(data)),
    deleteTemplate : (data) => dispatch(templateActions.deleteTemplate(data)),
    setBreadcrumb: (menu) => dispatch(menuActions.changeMenu(menu)),
    resetTemplateConfiguration: ()=>dispatch(settingsActions.resetEmailConfiguration())

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Template);


Template.propTypes = {
  templateDetails: PropTypes.object.isRequired,
  fetchTemplateList: PropTypes.func.isRequired,
  SetEditTemplateData: PropTypes.func.isRequired,
  deleteTemplate: PropTypes.func.isRequired,
  setBreadcrumb: PropTypes.func.isRequired,
  resetTemplateConfiguration: PropTypes.func.isRequired

}