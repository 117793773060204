import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

const SpinningLoader = ({circleColor="#fff",label="loading..."}) => {
    return ( 
        <div style={{textAlign:'center', padding:"20px"}}>
          <CircularProgress sx={{
              color: circleColor
          }} />        
          <br />
          <p style={{fontSize:"18px"}}>{label}</p>
        </div>
     );
}

SpinningLoader.defaultProps = {
    circleColor : "#fff",
    label:"loading..."
};

SpinningLoader.propTypes = {
    label : PropTypes.string,
    circleColor : PropTypes.string
};

export default SpinningLoader;