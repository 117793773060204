/* eslint-disable */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';

import * as menuActions from '../store/actions/breadcrumb';

const smsStatus = [
    {
        status: "Pending",
        definition: "All valid messages are marked as pending once the file has been uploaded and processed."
    },
    {
        status: "Queued",
        definition: "Message service has received your request to create the message. All new messages sent from a specific phone number are created with a status of queued."
    },
    {
        status: "Sent",
        definition: "Message service has received a confirmation from our Super Network partner advising they have accepted the message."
    },
    {
        status: "Delivered",
        definition: "Message service has received confirmation of message delivery from the carrier."
    },
    {
        status: "Undelivered",
        definition: "Message service has received a delivery receipt indicating that the message was not delivered. This can happen for a number of reasons including carrier content filtering, availability of the destination handset, etc."
    },
    {
        status: "Failed",
        definition: "The message could not be sent. This can happen for various reasons including queue overflows and account suspension."
    },
    {
        status: "Invalid",
        definition: "When a message fails to match the requirements of the platform format, it is marked as Invalid during processing. This may occur for a number of reasons, such as an incompatible template and message structure."
    },
    {
        status: "Cancelled",
        definition: "Messages are marked as cancelled, when a user cancelled the broadcast for an input data file."
    },
]

const emailStatus = [
    {
        status: "Pending",
        definition: "All valid messages are marked as pending once the file has been uploaded and processed."
    },
    {
        status: "Queued",
        definition: "This event fires when message service receives an individual message and prepares it to be delivered. Think of this as the top of the funnel-unless it is dropped (see below), each message you push to SendGrid will create a processed event."
    },
    {
        status: "Sent",
        definition: "The message has been sent from the platform, and message service will confirm that it has been received."
    },
    {
        status: "Delivered",
        definition: "When an email has been accepted at the receiving server, the delivered event fires. This event does not guarantee that the email was placed in the recipient’s inbox. In fact, a delivered email is only the beginning of an opaque process. The remaining four events begin to give us hints about whether anyone will ever see this delivered email."
    },
    {
        status: "Undelivered",
        definition: "If a server cannot or will not deliver a message, message service fires a bounce event. Bounces often are caused by outdated or incorrectly entered email addresses. Many times you won’t know a bounced email address until it bounces, so this event can help you ensure it doesn’t bounce again by removing it from your lists."
    },
    {
        status: "Failed",
        definition: `The message could not be sent. This can happen for various reasons including queue overflows and account suspension. Based on the response code, they are returning errors ["400":"Bad Request","403":"From address doesn't match Verified Sender Identity","406":"Missing Accept header. For example: Accept: application/json","429":"Too many requests/Rate limit exceeded","500":"Internal server error"].`
    },
    {
        status: "Invalid",
        definition: "When a message fails to match the requirements of the platform format, it is marked as Invalid during processing. This may occur for a number of reasons, such as an incompatible template and message structure."
    },
    {
        status: "Cancelled",
        definition: "Messages are marked as cancelled, when a user cancelled the broadcast for an input data file."
    },
]

const errorLog = [
    {
        status: "Invalid mobile number",
        definition: "If the mobile number is less or greater than 10 digits. (i.e. 5465256)"
    },
    {
        status: "Invalid Email ID",
        definition: "If the Email ID is empty."
    },
    {
        status: "Invalid date time",
        definition: "If the date and time in file not meet platform format. (i.e. 04/12-2023, 25:62)"
    },
    {
        status: "Invalid language",
        definition: "If the specified language code is not exist in a platform. (i.e. gr)"
    },
    {
        status: "Invalid template code",
        definition: "If the specified template code is less or greater than 6 digits or contains special characters. (i.e. CAMP, CAMP$$)"
    },
    {
        status: "Template code not mapped with template",
        definition: `Template code doesn’t exist in the platform.`
    },
    {
        status: "Template code not mapped with campaign",
        definition: "This will occur when a template created, but not associated with any campaign. It will occur when campaign mapping is removed from template from the DB."
    },
    {
        status: "Invalid file sid",
        definition: "If transaction ID is missing in a message."
    },
    {
        status: "Invalid file sent date",
        definition: "If the date in file not meet platform format. (i.e. 04/12-2023)"
    },
    {
        status: "Invalid message",
        definition: "Message structure with curly braces that doesn't meets platform format - {{name}"
    },
    {
        status: "Empty message",
        definition: "Message is empty."
    },
    {
        status: "Invalid type",
        definition: "A field is entered other than user/template."
    },
    {
        status: "Invalid structure",
        definition: "Format of record not meets platform format (i.e. Email|||||)"
    },
    {
        status: "Channel mismatch expected Text - Found",
        definition: "Email file is upload to SMS Channel / Text folder in s3 bucket."
    },
    {
        status: "Channel mismatch expected Email - Found",
        definition: "Text File is upload to Email Channel / Email folder in s3 bucket."
    },
    {
        status: "Template not found for language specified",
        definition: "Given language not in a found within the template."
    },
    {
        status: "Empty message template",
        definition: "Message template is missing from the s3 bucket database."
    },
]

const Glossary = ({ setBreadcrumb }) => {

    useEffect(() => {
        setBreadcrumb('Glossary')
    }, [])

    return <div style={{ minHeight: "80vh" }}>
        <Grid item xs={6} sm={6} xl={6} lg={6} md={6}>
            <h1 className='page-heading' ><b>Glossary</b></h1>
        </Grid>
        <hr style={{ border: "3px solid #EDF6F5", margin: "20px 0" }} />
        <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
            <div>
                <h3 ><b>Campaign</b></h3>
                <p>A campaign is a planned set of communication activities for clients. Campaigns are broadly categorized into two types</p>
                <u1>
                    <li>An ongoing operational initiative to send periodic messages to clients.</li>
                    <li>A communication initiative for a specific business activity, such as an emergency notification.</li>
                </u1>
            </div>
            <div>
                <h3 ><b>Template</b></h3>
                <p >A template is a content placeholder for message content. In K Notify, a template is always associated with only one campaign</p>
            </div>
            <div>
                <h3 ><b>Communication Channel</b></h3>
                <p>Channel is the medium of communication. It can be either SMS, Email or voice</p>
            </div>
            <div>
                <h3 ><b>Data File</b></h3>
                <p>Platform input file in DAT format, which contains messages to be sent to clients.</p>
            </div>
            <div>
                <h3 ><b>SMS Status Definition</b></h3>
                <div>
                    <table className='content-table'>
                        <thead>
                            <th className='content-th'>Status</th>
                            <th className='content-th'>Definition</th>
                        </thead>
                        <tbody>
                            {smsStatus.map((item) => (
                                <tr>
                                    <td className='content-td'>{item.status}</td>
                                    <td className='content-td'>{item.definition}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <h3 ><b>Email Status Definition</b></h3>
                <div>
                    <table className='content-table'>
                        <thead>
                            <th className='content-th'>Status</th>
                            <th className='content-th'>Definition</th>
                        </thead>
                        <tbody>
                            {emailStatus.map((item) => (
                                <tr>
                                    <td className='content-td'>{item.status}</td>
                                    <td className='content-td'>{item.definition}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <h3><b>Instant Notification</b></h3>
                <p>Is a feature where K Notify users will be able to broadcast on demand SMS and Email messages, to a set of phone numbers or Email IDs. These could be messages to internal staffs or clients.</p>
            </div>
            <div>
                <h3 ><b>Error Log Definition</b></h3>
                <div>
                    <table className='content-table'>
                        <thead>
                            <th className='content-th'>Reason</th>
                            <th className='content-th'>Definition</th>
                        </thead>
                        <tbody>
                            {errorLog.map((item) => (
                                <tr>
                                    <td className='content-td'>{item.status}</td>
                                    <td className='content-td'>{item.definition}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Grid>

    </div>
}


Glossary.propTypes = {
    setBreadcrumb: PropTypes.func.isRequired

}

const mapStateToProps = (state) => ({ optOutData: state.optout })

const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumb: (menu) => dispatch(menuActions.changeMenu(menu))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Glossary);


