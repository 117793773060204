import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import { useMsal } from "@azure/msal-react";

import Header from './Header';
import SideMenu from './SideMenu';
import idleTimer  from '../components/Helper/idleTimer';

const Content = styled.div`
 padding: 1rem;

@media (max-width: 1390px) {
    padding: 1rem;
}
@media (max-width: 1200px) {
    padding: 1rem ;
    margin: 0 1rem;
}

`;

const ContentWrapper = styled.div`
background:#ECF6F4;
@media (min-width: 1200px) {
    padding: 0 0 1rem 2rem ;
}
@media (min-width: 1420px){
    margin: 0 0 1rem 0rem ;
}
@media (max-width: 1600px){
    padding: 0 0 1rem 0rem ;
}
@media (max-width: 1200px) {
    padding: 0 0 0 1rem ;
}
@media (min-width:1200px ) and (max-width: 1400px) {
    padding: 0 0 1rem 2rem ;
}
@media (min-width: 1900px) and (max-width: 2200px){
    margin: 0 0 0 -2rem ;
}
@media (min-width: 2200px) and (max-width: 2400px){
    margin: 0 0 0 -4rem ;
}
@media (min-width: 2400px) and (max-width: 2600px){
    margin: 0 0 0 -6rem ;
}
@media (min-width: 2600px){
    margin: 0 0 0 -8rem ;
}
`;

const MainLayout = props => {
    const { children } = props;
    const { instance } = useMsal();

    useEffect(()=>{
        idleTimer(instance);
    },[])

    return (
        
    <ContentWrapper>
        <Grid container>
            <Grid  role="navigation"  item padding={{ left: 0}} display={{ xs: "none", sm: "none", xl:"block", lg:"block", md:"none" }} xl={2} lg={2} md={2}>
                <SideMenu />
            </Grid>
          
            <Grid style={{marginTop:"90px"}} item xs={12} sm={11} xl={9.7} lg={9.7} md={9.7}>
                <Header/>
                <div className='content-wrapper'>
                <Content role="main" style={{marginBottom:"50px"}}>
                {children}
                </Content>
                </div>
            </Grid>
        </Grid>
    </ContentWrapper>
      

    );
}

MainLayout.propTypes = {
    children: PropTypes.node.isRequired
}
export default MainLayout;
