import * as actionTypes from '../actions/actionTypes';

const initialState = {  
  dashboardDetails:{},  
  isLoading: false,
  isError: false
}

const dashboardReducer = (state = initialState, action = {}) => {

  const newState = { ...state };

  switch (action.type) {
    case actionTypes.FETCH_DASHBOARD_DETAILS:
      return { ...newState, isLoading: true };

    case actionTypes.FETCH_DASHBOARD_DETAILS_SUCCESS:
      return { ...newState, isLoading: false, dashboardDetails: { ...action.payload } };

    case actionTypes.FETCH_DASHBOARD_DETAILS_FAILURE:
      return { ...newState, isLoading: false, dashboardDetails: { ...newState.dashboardDetails }, isError: true };
    
    default:
      return { ...newState };
  }
};


export default dashboardReducer;