import React, { useRef } from 'react';
import styled from 'styled-components';
import { TextField, Button } from '@mui/material';
import PropTypes from 'prop-types';
import appConfig from '../../config/appConfig';

const CustomTextField = styled(TextField)({
    '& .MuiInputBase-input': {
        position: 'relative',
        background: '#fcfcfb',
        fontSize: 16,
        height: "12px",
        alignItems: "center",
        textDecoration: "black",
        borderBottomRightRadius: "0px  !important",
        borderTopRightRadius: "0px  !important",
        'focus': {
            borderColor: '1px solid #24A5DA'

        },
    },

})
const CampaignButton = styled(Button)`
  background-color: ${appConfig.colors.white};
  border: 1px solid rgba(0, 0, 0, 0.26) !important;
  margin-left: 10px;
  color: #FFFBF9;
  min-width: 110px;
  text-align: center;
`;
const FileUploadWrapper = styled.div`
  display: flex;
// margin-top:25px;
`;

const FileUpload = ({
    onChange,
    selectedFile,
    fileType = true,
    file,
    disabled = false,
    icon
}) => {
    const upoladRef = useRef();

    const uploadClick = () => {
        if (fileType) {
            upoladRef.current.click();
        }
    }

    return (
        
        <FileUploadWrapper >
           
            <CustomTextField
                disabled
                className='file-upload'
                sx={{ '& .MuiInputBase-root .MuiInputBase-input': { background: (disabled) ? "#E9F2FA" : "#FFF", borderRadius:"5px" ,border:`1px solid ${appConfig.colors.darkblue}`}}}
                aria-label={selectedFile ? "Campaign File name" : "Choose File"}
                placeholder={selectedFile ? selectedFile?.name : 'Choose File'}

                fullWidth
                variant="outlined" />
            {fileType && (
                <input
                    ref={upoladRef}
                    onChange={onChange}
                    accept={file}
                    id="contained-button-file"
                    multiple
                    type="file"
                    label='upload file'
                />
            )}
            <CampaignButton
                variant="contained"
                component="span"
                sx={{ fontFamily: appConfig.fontfamily, boxShadow: 'none', borderTopLeftRadius:0, borderBottomLeftRadius:0, left: '-10px',border:`1px solid ${appConfig.colors.darkblue}` }}
                onClick={uploadClick}
                disabled={disabled}
            >
                {icon}
            </CampaignButton>
            

        </FileUploadWrapper>
     
    );
}
FileUpload.defaultProps = {
    selectedFile: {},
    disabled: false,
    onChange: null
};
FileUpload.propTypes = {
    selectedFile: PropTypes.objectOf(PropTypes.object),
    onChange: PropTypes.func,
    fileType: PropTypes.bool.isRequired,
    file: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    disabled: PropTypes.bool
}

export default FileUpload;