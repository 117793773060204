import * as actionTypes from '../actions/actionTypes';

const initState = {
  isAuth:false,
  loading:false,
  isError:false,
  userDetails:{},
  registerDetails: {},
  forgotDetails: {},
  verifyCodeDetails: {},
  signOutDetails: {},
  errorMessage: {},
  isLoginSuccessfull:false,
  loginDetails:{}
}

const authReducer = (state = initState, action = {}) => {
  const newState = {...state};

    switch (action.type) {
      case actionTypes.SIGN_IN:
      return {...newState , isLoading: true};

      case actionTypes.SIGN_IN_SUCCESS:
        return {...newState , isLoading: false, isError:false, userDetails:{...action.payload}};

      case actionTypes.SIGN_IN_FAILURE:
        return {...newState , isLoading: false, isError:true, errorMessage: {...action.payload}};

      case actionTypes.SIGN_UP:
        return {...newState , isLoading: true};
        
      case actionTypes.SIGN_UP_SUCCESS:
        return {...newState , isLoading: false, isError:false, errorMessage:{}, registerDetails:{...action.payload}};

      case actionTypes.SIGN_UP_FAILURE:
        return {...newState , isLoading: false, isError:true, errorMessage: {...action.payload}, registerDetails:{} };

      case actionTypes.VERIFY_CODE:
        return {...newState , isLoading: true};
        
      case actionTypes.VERIFY_CODE_SUCCESS:
        return {...newState , isLoading: false, isError:false, verifyCodeDetails:{...action.payload}};

      case actionTypes.VERIFY_CODE_FAILURE:
        return {...newState , isLoading: false, isError:true, errorMessage: {...action.payload} };
    
      case actionTypes.FORGOT_PWD:
        return {...newState , isLoading: true};
        
      case actionTypes.FORGOT_PWD_SUCCESS:
        return {...newState , isLoading: false, isError:false, forgotDetails:{...action.payload}};

      case actionTypes.FORGOT_PWD_FAILURE:
        return {...newState , isLoading: false, isError:true, errorMessage: {...action.payload} };
    
      case actionTypes.SIGN_OUT:
        return {...newState , isLoading: true};

      case actionTypes.SIGN_OUT_SUCCESS:
        return {...newState , isLoading: false, isError:false, signOutDetails:{...action.payload}};
    
      case actionTypes.SIGN_OUT_FAILURE:
        return {...newState , isLoading: false, isError:true, errorMessage: {...action.payload} };
    
        case actionTypes.AZURE_SIGN_IN:
          return {...newState , isLoading: true};

        case actionTypes.AZURE_SIGN_IN_SUCCESS:
          window.sessionStorage.setItem("userInfo", JSON.stringify(action.payload));
          return {...newState , isLoading: false, isError:true, isLoginSuccessfull:true, loginDetails: {...action.payload} };
    
          case actionTypes.AZURE_SIGN_IN_FAILURE:
            return {...newState , isLoading: false, isError:true, isLoginSuccessfull:false, errorMessage: {...action.payload} };
        

      default:
        return state;
    }
  };
  export default authReducer;